@if $loyalty-redesign == true {
  .loyalty-landing {
    @include breakpoint($medium-up) {
      overflow: hidden;
    }
  }
  .pts__cash__icon {
    cursor: pointer;
    img {
      @include breakpoint($medium-up) {
        padding-left: 5px;
        width: 25px;
      }
      top: 3px;
      position: relative;
      width: 15px;
    }
  }
  .loyalty-pts-cash-overlay {
    &__description {
      @include breakpoint($medium-up) {
        padding: 20px 20px 30px;
      }
      font-family: $font--futura-medium;
      padding: 30px 20px;
      .loyalty-pts-cash-overlay__benefits {
        li {
          font-size: 15px;
          letter-spacing: 1px;
          list-style-type: disc;
        }
        padding-left: 40px;
      }
      p {
        font-size: 18px;
      }
    }
    &__title {
      text-align: center;
      text-transform: uppercase;
    }
  }
  .loyalty-tree-popup-overlay,
  .loyalty-pts-cash-overlay {
    #cboxLoadedContent {
      @include breakpoint($medium-up) {
        background-color: $color-white;
      }
      background-color: $color-overlay-gray;
    }
    #cboxClose {
      background: none;
      text-indent: 0;
      .icon--close {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .messages__container__loyalty {
    text-align: center;
    .success__message,
    .error__message {
      @include breakpoint($medium-up) {
        width: 60%;
      }
      width: 90%;
      margin: 0 auto;
      height: auto;
      padding: 6px 0;
      border: 2px solid $color-darker-green;
      color: $color-darker-green;
      letter-spacing: 0.5px;
      border-radius: 4px;
      background-color: $color-silver-green;
      font-size: 16px;
      font-family: $font--futura-medium;
    }
    .error__message {
      border: 2px solid $color-red;
      color: $color-red;
      background-color: $color-light-red;
    }
  }
  .plant_tree_popup {
    padding: 55px 40px 30px;
    text-align: center;
    .redeem__title {
      @include breakpoint($medium-up) {
        padding: 0 25px;
        font-size: 25px;
      }
      font-size: 36px;
      font-family: $font--futura-book;
      font-weight: normal;
      letter-spacing: -0.02em;
      text-align: center;
    }
    .points__description {
      font-size: 16px;
      font-weight: normal;
      letter-spacing: -0.02em;
      font-family: $font--futura-book;
      text-align: center;
      margin: 22px 0 26px;
    }
    .enroll__btn {
      @include breakpoint($medium-up) {
        letter-spacing: 0;
      }
      padding: 5px 22px;
      color: $color-white;
      background-color: $color-drak-black;
      border: 1px solid $color-drak-black;
      font-size: 15px;
      text-align: center;
      letter-spacing: 1px;
      cursor: pointer;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  .sign-in_content_wrapper {
    .loyalty__benefits__table {
      .benefits__title {
        @include breakpoint($medium-up) {
          margin-top: 0;
          padding-top: 40px;
        }
      }
    }
    .marketing {
      &__points {
        @include breakpoint($medium-up) {
          padding-top: 70px;
        }
        padding-top: 10px;
      }
      &__points,
      &__faq {
        @include breakpoint($medium-up) {
          max-width: none;
          margin-left: 0;
          border-left: none;
        }
      }
      &__faq {
        &__header {
          @include breakpoint($medium-up) {
            padding: 20px 5%;
          }
        }
      }
    }
  }
  .loyalty__navigation {
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
    margin-top: 48px;
  }
  .loyalty_wrapper,
  .navigation__common__wrapper {
    .mobile__navigation {
      .account-utilities {
        display: block;
        padding: 0;
        border-top: 1px solid $color-black;
      }
      .account-utilities__section {
        margin-bottom: 0;
      }
      .profile-pic-main {
        padding: 20px 15px 15px;
        float: left;
        width: 46%;
      }
      .account-utilities__greetings {
        padding: 20% 1%;
        float: left;
        width: 54%;
      }
      .account-utilities__greeting {
        color: $color-drak-black;
        letter-spacing: 1px;
      }
      .account-utilities__sign-out {
        a {
          text-transform: none;
          text-decoration: underline;
          letter-spacing: 1px;
          color: $color-drak-black;
        }
      }
      .account-utilities__menus {
        clear: both;
      }
      .sidebar-menu__item--points,
      .sidebar-menu__item--auto-replenish {
        display: none;
      }
      .sidebar-menu {
        padding: 20px 15px 15px;
        margin-bottom: 0;
        &__item {
          &.selected {
            display: inline-block;
            border-bottom: 1px solid $color-drak-black;
          }
          margin-bottom: 20px;
          font-size: 13px;
          letter-spacing: 0.5px;
          a {
            color: $color-black;
          }
          &.nav {
            &__my__account {
              clear: both;
            }
            &__my__account,
            &__account--loyalty {
              color: $color-drak-black;
              text-transform: uppercase;
              font-size: 15px;
              letter-spacing: 1px;
              font-family: $font--futura-medium;
            }
            &__account--loyalty {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  .sign-in-page {
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
    margin-top: 60px;
    .navigation__menu__wrapper {
      padding: 0;
      #sign-in__navigation {
        .account-utilities {
          margin-top: 25px;
        }
      }
    }
    .loyalty__panel__points {
      &__header {
        .nav__section {
          display: none;
        }
      }
      &__title {
        border-left: none;
        width: 100%;
      }
    }
  }
  .loyalty__points__section {
    &.loyalty__points__index {
      padding-top: 30px;
    }
    @include breakpoint($medium-up) {
      padding: 20px 0 45px;
    }
    @include breakpoint($medium-portrait-only) {
      margin-top: 50px;
    }
    padding: 20px 20px 0;
    &__title {
      @include breakpoint($medium-up) {
        font-size: 32px;
        letter-spacing: 1px;
        margin-bottom: 0;
      }
      font-family: $font--futura-demi;
      margin: 0;
      line-height: 1;
      font-size: 20px;
      font-weight: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;
      border-bottom: 1px solid $color-light-gray;
      padding: 20px;
      margin-bottom: 20px;
    }
    &__subtitle {
      @include breakpoint($medium-up) {
        letter-spacing: 0;
        padding: 20px;
        margin-bottom: 0;
        font-size: 16px;
      }
      font-family: $font--futura-demi;
      text-align: center;
      line-height: 1;
      font-size: 14px;
      letter-spacing: 0;
      color: $color-green;
      .terms-link {
        text-decoration: underline;
      }
    }
    &__my-status {
      @include breakpoint($medium-up) {
        width: 50%;
      }
      width: 100%;
      padding-top: 20px;
      display: inline-block;
      text-align: center;
      .loyalty__points__status {
        max-width: 340px;
        margin: 0 auto;
        background: url('/media/export/cms/loyalty/points_chart_text_tier_1.png') no-repeat;
        background-size: 100%;
        padding: 110px 0 50px;
        max-height: 240px;
        @include breakpoint($medium-up) {
          padding: 20% 0 8%;
        }
        @include breakpoint($medium-landscape-only) {
          padding: 30% 0 20%;
        }
        &.tier-2 {
          background: url('/media/export/cms/loyalty/points_chart_text_tier_2.png') no-repeat;
        }
        &.tier-3 {
          @include breakpoint($medium-up) {
            padding: 20% 0;
          }
          padding: 30% 0;
          background: url('/media/export/cms/loyalty/points_chart_text_tier_3.png') no-repeat;
          background-size: 100%;
        }
        &__header {
          font-family: $font--futura-medium;
          font-size: 18px;
          line-height: 1;
          margin: 0 0 20px;
          min-height: 64px;
          @include breakpoint($landscape-up) {
            font-size: 32px;
          }
        }
        &__text {
          font-family: $font--futura-medium;
          font-size: 15px;
          line-height: 1;
          font-weight: initial;
          span {
            width: 15px;
            height: 16px;
            background: url('/media/export/cms/loyalty/point_chart_disclaimer.png') no-repeat;
            padding-left: 15px;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
      .points-title {
        @include breakpoint($medium-up) {
          display: block;
        }
        margin: 0;
        display: none;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 1px;
        padding-bottom: 10px;
      }
      .seedling-status,
      .sapling-status,
      .sycamore-status {
        @include breakpoint($medium-up) {
          width: 30%;
        }
        width: 31%;
        height: 10px;
        display: inline-block;
        margin-right: 5px;
        background-color: $color-green;
        opacity: 0.4;
        &.selected {
          opacity: 1;
        }
      }
      .seedling-name,
      .sapling-name,
      .sycamore-name {
        @include breakpoint($medium-up) {
          width: 30%;
          color: $color-drak-black;
          font-size: 12px;
          font-family: $font--futura-medium;
          letter-spacing: 1.5px;
        }
        font-size: 15px;
        font-weight: normal;
        color: $color-green;
        font-family: $font--futura-demi;
        display: inline-block;
        width: 31%;
        opacity: 0.4;
        letter-spacing: 1px;
        text-transform: uppercase;
        &.selected {
          opacity: 1;
        }
      }
      .sapling-name,
      .sycamore-name {
        margin-left: 5px;
      }
      .rewards {
        &__container {
          @include breakpoint($medium-up) {
            text-align: left;
          }
          text-align: center;
        }
        &-btn {
          @include breakpoint($medium-up) {
            letter-spacing: 0.5px;
            font-size: 12px;
          }
          font-size: 15px;
          font-family: $font--heading--secondary;
          letter-spacing: 1px;
          cursor: pointer;
          background-color: $color-drak-black;
          color: $color-white;
          padding: 5px 19px;
          font-weight: 200;
          text-align: center;
          white-space: nowrap;
          margin-top: 20px;
          border: none;
        }
      }
      .anniversary {
        &-text {
          @include breakpoint($medium-up) {
            font-size: 13px;
            font-family: $font--futura-book;
          }
          font-family: $font--futura-demi;
          font-size: 15px;
          letter-spacing: 1px;
          padding-top: 20px;
        }
        &-date {
          font-family: $font--futura-demi;
        }
      }
    }
    &__level {
      @include breakpoint($medium-up) {
        width: 50%;
        float: right;
        padding-top: 20px;
        text-align: left;
      }
      text-align: center;
      width: 100%;
      float: none;
      display: inline-block;
      padding-top: 0;
      .anniversary-text {
        @include breakpoint($medium-up) {
          font-family: $font--futura-demi;
        }
        font-family: $font--futura-book;
        font-size: 15px;
        text-transform: uppercase;
        margin-top: 15px;
        letter-spacing: 1px;
        &__date {
          @include breakpoint($medium-up) {
            font-family: $font--futura-book;
          }
          font-family: $font--futura-demi;
          font-size: 16px;
        }
      }
      .level {
        &-header {
          @include breakpoint($medium-up) {
            font-family: $font--futura-book;
            font-size: 32px;
            margin: 0 0 30px;
            letter-spacing: 0;
          }
          margin-top: 0;
          font-size: 18px;
          font-weight: normal;
        }
        &-title {
          @include breakpoint($medium-up) {
            text-transform: uppercase;
            font-size: 15px;
            padding-bottom: 5px;
          }
          margin: 0;
          font-size: 18px;
          font-weight: normal;
          letter-spacing: 1px;
        }
        &-value {
          font-size: 32px;
          font-family: $font--futura-medium;
          color: $color-green;
          @include breakpoint($landscape-up) {
            font-size: 42px;
          }
        }
        &-text {
          @include breakpoint($medium-up) {
            margin-top: 20px;
          }
          font-size: 13px;
          letter-spacing: 1px;
          margin-top: 10px;
        }
      }
      .points-value {
        @include breakpoint($medium-up) {
          text-transform: uppercase;
          font-size: 13px;
        }
        padding-right: 8px;
        font-size: 16px;
        font-family: $font--futura-demi;
        &:empty {
          padding-right: 0;
        }
        &__level {
          font-family: $font--futura-book;
        }
      }
      .learn__more-link {
        &--pc {
          display: none;
        }
        @include breakpoint($medium-up) {
          font-size: 15px;
          font-family: $font--futura-medium;
          &--mobile {
            display: none;
          }
          &--pc {
            display: inline;
          }
        }
        letter-spacing: 1px;
        font-size: 18px;
        text-decoration: underline;
        font-family: $font--futura-book;
      }
      .shipment-text {
        @include breakpoint($medium-up) {
          width: 65%;
          font-size: 13px;
          color: $color-mid-gray;
        }
        font-size: 15px;
        color: $color-mid-gray;
        padding-top: 10px;
        width: 100%;
        line-height: 1.2;
      }
    }
  }
  // Marketing page specific changes for loyalty tout
  .marketing__enrollment {
    .loyalty__points__section {
      &__my-status {
        float: left;
      }
    }
  }
  .loyalty__page__index {
    @include breakpoint($medium-up) {
      padding: 0 16px;
      border-left: 1px solid $color-light-gray;
      margin-top: 20px;
      overflow: visible;
    }
    @include breakpoint($medium-portrait-only) {
      border-left: none;
      padding: 0 16px;
    }
    @include breakpoint($medium-landscape-only) {
      padding: 0 16px;
    }
    overflow: hidden;
    .loyalty__page__tout {
      .loyalty__points__section {
        @include breakpoint($medium-up) {
          padding-bottom: 10px;
        }
      }
    }
    .loyalty__panel__points {
      @include breakpoint($medium-up) {
        border-bottom: 1px solid $color-light-gray;
      }
      &__header {
        @include breakpoint($medium-up) {
          border-bottom: 1px solid $color-light-gray;
        }
        border-bottom: 1px solid $color-black;
        width: 100%;
        display: inline-block;
        .nav__section {
          @include breakpoint($medium-up) {
            display: none;
            float: none;
          }
          padding: 13px 0;
          float: left;
          display: inline-block;
          width: 13%;
          &__link {
            display: block;
          }
          &__back-btn {
            background-color: transparent;
            background-image: url(/media/export/cms/global/carousel.arrows.png);
            background-repeat: no-repeat;
            height: 35px;
            -webkit-transition: none;
            -moz-transition: none;
            transition: none;
            width: 33px;
            background-position-x: 14px;
          }
        }
      }
      &__subheader {
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 1px;
        font-family: $font--futura-book;
        padding: 0 20px 20px;
        @include breakpoint($medium-up) {
          font-family: $font--futura-demi;
          text-transform: uppercase;
          font-size: 16px;
          margin-top: 0;
        }
      }
      &__title {
        @include breakpoint($medium-up) {
          margin: 0;
          width: 100%;
          float: none;
          border-left: none;
          font-size: 28px;
        }
        @include breakpoint($medium-portrait-only) {
          margin-top: 40px;
        }
        padding: 20px 0;
        border-left: 1px solid $color-light-gray;
        border-bottom: none;
        float: left;
        display: inline-block;
        width: 80%;
        font-size: 20px;
        font-family: $font--futura-demi;
        font-weight: normal;
        letter-spacing: 0;
        text-align: center;
        p {
          font-family: $font--futura-book;
          text-transform: none;
          font-size: 16px;
          @include breakpoint($medium-up) {
            font-family: $font--futura-demi;
            text-transform: uppercase;
          }
        }
      }
    }
    .loyalty__page__earn {
      .loyalty__panel__points {
        border-bottom: none;
      }
    }
    #loyalty__page__earn {
      .loyalty__earn__points__section {
        &__title {
          @include breakpoint($medium-up) {
            font-size: 26px;
            font-family: $font--futura-book;
          }
          font-weight: normal;
          margin: 0;
          font-size: 18px;
          text-align: center;
          letter-spacing: 1px;
          font-family: $font--futura-demi;
        }
        &__subtitle {
          @include breakpoint($medium-up) {
            padding: 0px 0 14px;
            font-size: 16px;
            line-height: 1.42;
            text-transform: uppercase;
            font-family: $font--futura-demi;
          }
          text-align: center;
          font-size: 18px;
          font-family: $font--futura-book;
          letter-spacing: 1px;
          font-weight: normal;
          padding: 5px 0 20px;
        }
        &__point-details {
          text-align: center;
          .offer-details {
            @include breakpoint($medium-up) {
              width: 46%;
              border: 8px solid $color-green;
              min-height: 250px;
              float: left;
              margin: 20px 26px 20px 10px;
            }
            @include breakpoint($medium-up) {
              margin-right: 10px;
            }
            width: 90%;
            min-height: 292px;
            display: table;
            margin: 0 5%;
            border: 7px solid $color-green;
            text-align: center;
            padding: 0 40px;
            &__section {
              display: table-cell;
              vertical-align: middle;
            }
            &__shop {
              @include breakpoint($medium-up) {
                margin: 20px 26px 20px 10px;
                float: right;
              }
              margin: 20px 5%;
            }
            .point {
              &-details {
                @include breakpoint($medium-up) {
                  font-size: 50px;
                }
                @include breakpoint($medium-portrait-only) {
                  font-size: 44px;
                }
                text-transform: uppercase;
                font-size: 42px;
              }
              &-desc {
                @include breakpoint($medium-up) {
                  font-size: 16px;
                }
                font-size: 20px;
                padding-top: 8px;
              }
            }
            .submit-btn {
              @include breakpoint($medium-up) {
                height: 25px;
                font-size: 13px;
                letter-spacing: 1px;
                padding: 5px 19px;
              }
              width: auto;
              letter-spacing: 1.5px;
              font-size: 18px;
              cursor: pointer;
              background-color: $color-drak-black;
              color: $color-white;
              padding: 5px 33px;
              font-weight: 200;
              text-align: center;
              white-space: nowrap;
              margin-top: 20px;
              border: none;
              height: 30px;
              &.shop__best__sellers-btn {
                width: auto;
              }
            }
            .section-number {
              vertical-align: super;
              font-size: 9px;
              right: 5.5px;
              top: -7px;
            }
            .earned__points {
              padding-top: 12px;
              &-message {
                @include breakpoint($medium-up) {
                  font-size: 16px;
                }
                font-size: 18px;
                font-family: $font--futura-medium;
                color: $color-green;
              }
              .submit-more-link {
                @include breakpoint($medium-up) {
                  font-size: 16px;
                }
                font-size: 20px;
                padding-top: 12px;
                display: inline-block;
                text-decoration: underline;
              }
            }
            &.loyalty__panel__offers__offer {
              &.loyalty_offer_form {
                input[type='text'] {
                  @include breakpoint($medium-up) {
                    width: 55%;
                    height: 25px;
                    border: 1px solid $color-cr18-black;
                    padding-left: 10px;
                  }
                  font-size: 14px;
                  width: 83%;
                  vertical-align: bottom;
                  line-height: 1;
                  height: 30px;
                  border: 1px solid $color-mid-gray;
                }
                input {
                  &::-webkit-input-placeholder {
                    font-size: 14px;
                    line-height: 4;
                    font-family: $font--futura-book;
                    color: $color-cr18-black;
                  }
                }
              }
            }
            &__button__container {
              @include breakpoint($medium-up) {
                display: inline;
              }
              display: block;
              text-align: center;
            }
          }
        }
        &__footer {
          @include breakpoint($medium-up) {
            margin: 15px 0;
          }
          clear: both;
          margin: 15px 10px;
          &__conditions,
          &__program {
            @include breakpoint($medium-up) {
              display: block;
            }
            text-align: center;
            font-size: 14px;
            color: $color-mid-gray;
            .click-here-link {
              text-decoration: underline;
            }
          }
          .footer__points {
            padding-left: 10px;
            &-number {
              font-size: 9px;
              vertical-align: super;
              top: -2px;
              right: 1px;
            }
          }
        }
      }
      .loyalty__points__section {
        @include breakpoint($medium-portrait-only) {
          margin-top: 0;
        }
        &__title {
          display: none;
        }
      }
    }
    .loyalty__panel__rewards {
      &__title {
        @include breakpoint($medium-up) {
          font-size: 26px;
          padding-top: 0;
          margin-top: 30px;
        }
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        font-family: $font--futura-book;
        padding: 15% 5% 0;
        text-transform: uppercase;
      }
      &__subtitle {
        @include breakpoint($medium-up) {
          padding: 0 10px 20px;
          font-family: $font--futura-demi;
          text-transform: uppercase;
          font-size: 16px;
          margin-top: 0;
        }
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 1px;
        font-family: $font--futura-book;
        padding: 0 5%;
      }
      .messages__container__loyalty {
        @include breakpoint($medium-up) {
          margin-bottom: 20px;
        }
      }
      .product-grid__wrapper {
        @include breakpoint($medium-up) {
          padding: 0;
        }
        padding: 0 5%;
        .product-grid__content {
          margin-bottom: 20px;
          .product-grid__item {
            @include breakpoint($medium-up) {
              padding: 0 0.5em 3em 0.5em;
              width: 33.33%;
            }
            padding: 0 15px;
            width: 50%;
            float: left;
          }
          .slick-prev,
          .slick-next {
            @include breakpoint($medium-up) {
              top: 40%;
              width: 20px;
            }
            background-color: transparent;
            background-image: url(/media/export/cms/global/carousel.arrows.png);
            background-repeat: no-repeat;
            height: 55px;
            margin: -27px 0 0 0;
            position: absolute;
            top: 25%;
            text-indent: -9999px;
            -webkit-transition: none;
            -moz-transition: none;
            transition: none;
            width: 14px;
            opacity: 1;
            &.slick-disabled {
              opacity: 0.5;
            }
          }
          .slick-prev {
            @include breakpoint($medium-up) {
              left: -20px;
            }
            left: -14px;
          }
          .slick-next {
            @include breakpoint($medium-up) {
              right: -22px;
              top: 38%;
            }
            background-position: right;
            top: 22%;
            right: -14px;
            &:before {
              right: 5px;
            }
          }
          .product__points {
            &__details {
              @include breakpoint($medium-up) {
                min-height: 22px;
              }
              &.tree__offer {
                @include breakpoint($medium-up) {
                  padding-top: 3px;
                }
                .points__level {
                  @include breakpoint($medium-up) {
                    height: inherit;
                  }
                  height: 59px;
                }
              }
              @include breakpoint($small-down) {
                min-height: 47px;
                clear: both;
                display: block;
              }
              display: inline-block;
            }
          }
          .product-brief__detail {
            .product-brief__description {
              @include breakpoint($medium-up) {
                padding-bottom: 10px;
                min-height: initial;
              }
              min-height: 295px;
              padding-bottom: 16px;
              &:hover {
                color: $color-drak-black;
              }
              .product-brief__title {
                @include breakpoint($medium-up) {
                  min-height: 38px;
                }
                min-height: 34px;
                margin-bottom: 3px;
              }
              .product-brief__subtitle {
                @include breakpoint($medium-up) {
                  min-height: 30px;
                }
                min-height: 75px;
                text-transform: none;
              }
              .points__level {
                @include breakpoint($medium-up) {
                  display: inline;
                  margin-top: 0;
                }
                @include reset-styles;
                display: inline-block;
                margin-top: 10px;
                text-transform: uppercase;
                font-family: $font--futura-demi;
              }
              .product__size {
                @include breakpoint($medium-up) {
                  display: inline;
                  padding-left: 10px;
                }
                @include reset-styles;
              }
              .swatch__container,
              .shade__name {
                display: inline-block;
              }
              .shade__name {
                margin: 0;
                font-family: $font--futura-medium;
                font-size: 15px;
                letter-spacing: 0;
                text-transform: capitalize;
                font-weight: normal;
              }
              .shade__container {
                @include breakpoint($medium-up) {
                  display: inline-block;
                  padding-left: 5px;
                  min-height: auto;
                }
                min-height: 22px;
              }
              .swatch__container {
                width: 17px;
                height: 17px;
                border-radius: 17px;
                position: relative;
                top: 3px;
              }
            }
            .product-brief__cta-section {
              margin-top: 3px;
              display: inline-block;
              width: 100%;
              .product-brief__cta {
                &-add-to-bag {
                  display: inline-block;
                  width: 100%;
                }
              }
              .product-add-to-bag {
                @include breakpoint($medium-up) {
                  width: auto;
                }
                width: 100%;
                cursor: pointer;
                letter-spacing: 0.5px;
                &:hover {
                  background-color: $color-drak-black;
                }
                &.cart_selected {
                  background: $color-white;
                  color: $color-drak-black;
                  border: 1px solid $color-drak-black;
                }
                &.disabled {
                  opacity: 0.5;
                  cursor: not-allowed;
                }
              }
            }
          }
        }
      }
    }
    .loyalty__panel__earnpoints {
      &__landing {
        border-left: none;
        margin: 0 0 50px;
        padding-top: 0;
        .marketing {
          &__points__content {
            @include breakpoint($medium-up) {
              margin: 30px 0;
            }
            margin: 20px 0 0;
          }
          &__points__list {
            @include breakpoint($medium-up) {
              border-right: 1px solid $color-cr18-black;
              display: inline-block;
              padding: 0 30px;
            }
            @include breakpoint($medium-portrait-only) {
              padding: 5px 40px;
              display: block;
              border-right: none;
            }
            display: block;
            border-right: none;
            padding: 0;
            &:first-child {
              padding-left: 0;
              @include breakpoint($medium-portrait-only) {
                padding-right: 0;
              }
            }
            &:last-child {
              padding-right: 0;
              border-right: none;
              @include breakpoint($medium-portrait-only) {
                padding-left: 0;
              }
            }
            &__header {
              @include breakpoint($medium-up) {
                font-size: 24px;
                font-weight: normal;
                letter-spacing: 0;
              }
              font-size: 18px;
              font-family: $font--futura-medium;
              letter-spacing: 2px;
            }
            &__subheader {
              @include breakpoint($medium-up) {
                letter-spacing: 0;
                padding-bottom: 0;
              }
              @include reset-styles;
              letter-spacing: 1px;
              padding-bottom: 20px;
            }
          }
          &__points__header {
            @include breakpoint($medium-up) {
              font-size: 26px;
              font-weight: normal;
              font-family: $font--futura-book;
            }
            font-size: 18px;
            font-family: $font--futura-demi;
            font-weight: normal;
          }
          &__points__subheader {
            @include breakpoint($medium-up) {
              font-family: $font--futura-demi;
              text-transform: uppercase;
            }
            font-family: $font--futura-book;
            text-transform: none;
          }
          &__points__disclaimer {
            &__terms {
              @include reset-styles;
            }
            &__list__item {
              letter-spacing: 0;
            }
          }
        }
      }
      &__title {
        @include breakpoint($medium-up) {
          font-size: 28px;
          letter-spacing: 0;
          padding-top: 30px;
          font-family: $font--futura-book;
        }
        margin: 0;
        font-size: 15px;
        letter-spacing: 1px;
        padding-top: 50px;
        font-family: $font--futura-medium;
      }
      &__enter__points {
        @include breakpoint($medium-up) {
          font-size: 16px;
          letter-spacing: normal;
        }
        text-decoration: underline;
        font-family: $font--futura-book;
        font-size: 18px;
        padding-top: 10px;
        display: block;
        letter-spacing: 1px;
      }
    }
  }
  .loyalty_wrapper {
    .mobile__navigation {
      .sidebar-menu {
        &__item {
          &--loyalty-marketing {
            @include breakpoint($small-down) {
              display: list-item;
            }
          }
        }
      }
    }
  }
}
