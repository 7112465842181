.recommended-item {
  .recommended-item {
    &__product {
      &-image {
        &,
        &__image {
          @include breakpoint($medium-up) {
            max-height: 260px;
          }
        }
      }
      &-info {
        text-align: left;
        padding: 0;
        &__name {
          margin-top: 10px;
          font-family: $font--futura-demi;
          font-size: 15px;
        }
        &__subname {
          font-family: $font--futura-medium;
          font-size: 15px;
          text-transform: capitalize;
          padding-bottom: 10px;
          line-height: 18px;
        }
        &__price {
          font-family: $font--futura-book;
          font-size: 15px;
          margin: 15px 0;
        }
      }
    }
    &__cta {
      text-align: left;
    }
  }
}
