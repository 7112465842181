// Changes only iPad Protrait
@include breakpoint($medium-portrait-only) {
  .opc__shipping,
  .opc__payment,
  .opc__review {
    .checkout__content {
      .checkout__title--header {
        &.shipping_head {
          .edit,
          p {
            display: none;
          }
        }
      }
    }
    .checkout__panel {
      &--billing {
        .existing-address {
          .new-address {
            display: none;
          }
        }
        .billing_head {
          padding: 15px 0 0;
        }
      }
      &--shipping__edit-address {
        padding-top: 10px;
      }
      .sms-promotions,
      .payment-giftcard {
        .expando-block__icon {
          display: none;
        }
      }
      .payment-giftcard {
        .has-giftcard {
          display: block;
        }
      }
      .payment_method-container {
        .form-container {
          > .payment-control {
            width: 60%;
          }
        }
      }
      .paypal-address {
        .paypal-option-checkbox {
          &:before {
            margin-top: 11px;
          }
        }
      }
    }
  }
}

#sign-in-panel {
  .email-promotions {
    .pc_email_promo_container {
      .label-content {
        a {
          display: inline-block;
        }
      }
    }
  }
}
