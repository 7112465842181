@if $loyalty-redesign == true {
  .account-utilities {
    @include breakpoint($medium-up) {
      display: block;
      .account-utilities__section {
        .account-utilities {
          &__greeting {
            font-size: 14px;
            margin-bottom: 12px;
            color: $color-cr18-black;
          }
          &__sign-out {
            margin-bottom: 45px;
            a {
              text-transform: none;
              color: $color-cr18-black;
            }
          }
        }
        .sidebar-menu {
          &__item {
            font-size: 14px;
            margin-bottom: 12px;
            color: $color-drak-black;
            a {
              color: $color-drak-black;
            }
            &.disabled_nav_link {
              color: $color-gray;
            }
            &_loyalty {
              &_curr_pts {
                font-style: normal;
                font-size: 14px;
              }
              &_points {
                font-style: normal;
                color: $color-green;
                font-size: 30px;
                font-family: $font--futura-medium;
                margin-bottom: 35px;
              }
            }
            &.selected {
              a {
                border-bottom: 1px solid $color-drak-black;
              }
            }
            &.nav {
              &__my__account {
                color: $color-drak-black;
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 1px;
                font-family: $font--futura-medium;
              }
              &__account--loyalty {
                margin-top: 60px;
                color: $color-drak-black;
                font-family: $font--futura-medium;
                font-size: 12px;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
    @include breakpoint($medium-portrait-only) {
      display: none;
    }
    display: none;
  }
  .account__page__non__loyalty {
    @include breakpoint($medium-up) {
      padding: 0 40px;
      margin-bottom: 0;
    }
    padding: 0 20px;
    text-align: center;
    margin-bottom: 60px;
    &__header {
      @include breakpoint($medium-up) {
        font-size: 28px;
        padding-top: 10px;
      }
      padding-top: 20px;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 1;
      font-family: $font--futura-demi;
      font-weight: normal;
    }
    &__subheader {
      @include breakpoint($medium-up) {
        margin: 10px 0 5px;
        font-size: 16px;
      }
      font-size: 4.2vw;
      margin-top: 20px;
      letter-spacing: 1px;
      &__promotions {
        @include breakpoint($medium-up) {
          display: inline;
        }
        display: block;
      }
    }
    .birthday__title {
      font-size: 15px;
      letter-spacing: 1px;
      margin: 20px 0 15px;
    }
    .join__enrollment {
      @include breakpoint($medium-up) {
        font-size: 16px;
        letter-spacing: normal;
      }
      line-height: 1.4;
      font-size: 4.2vw;
      letter-spacing: 1px;
    }
    &__buttons {
      margin-top: 25px;
    }
    &__join__now {
      &__link {
        @include breakpoint($medium-up) {
          margin-right: 15px;
        }
        background-color: $color-drak-black;
        color: $color-white;
        margin-right: 10px;
        &.btn--disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
    &__learn__more {
      &__link {
        display: inline-block;
        color: $color-drak-black;
        background-color: $color-white;
      }
    }
    &__join__now,
    &__learn__more {
      &__link {
        @include breakpoint($medium-up) {
          font-size: 16px;
          letter-spacing: normal;
          padding: 5px 30px;
        }
        border-radius: 0;
        width: 135px;
        padding: 7px 20px;
        letter-spacing: 1px;
        font-size: 18px;
        line-height: 1.2;
        border: 1px solid $color-drak-black;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .loyalty__panel__join {
      &__form__enroll {
        a {
          text-decoration: underline;
        }
        @include breakpoint($medium-up) {
          padding-top: 25px;
          .label {
            width: 630px;
          }
        }
        .messages__container__loyalty {
          padding-bottom: 20px;
        }
        padding-top: 15px;
        .enrolment__optin {
          @include breakpoint($medium-up) {
            padding: 0;
          }
          padding: 0 5%;
        }
        .birth__month,
        .birth__date {
          @include breakpoint($medium-up) {
            width: 20%;
          }
          height: 35px;
          line-height: 1.7;
          width: 46%;
          display: inline-block;
          text-transform: none;
          font-size: 16px;
          min-width: auto;
          &.error {
            border-color: $color-red;
          }
        }
        .birth__month {
          margin-right: 2%;
        }
        .label-content {
          @include breakpoint($medium-up) {
            font-size: 16px;
          }
          text-align: left;
          line-height: 1.2;
          font-size: 4.2vw;
          letter-spacing: 1px;
        }
      }
    }
  }
  .loyalty_wrapper {
    .sign-in_content--loyalty {
      @include breakpoint($medium-up) {
        padding-left: 0;
        float: left;
      }
    }
  }
  .page-header {
    .utility-nav {
      &__loyalty-text {
        @include breakpoint($medium-up) {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 25px;
          color: $color-white;
          letter-spacing: 1px;
        }
      }
    }
    .loyalty__gnav-formatter__wrapper {
      .gnav-formatter {
        &__list {
          @include breakpoint($medium-up) {
            height: auto;
          }
          height: 84vh;
          border-bottom: none;
          .loyalty__points__section {
            padding: 15px;
            border-bottom: 1px solid $color-drak-black;
            .profile {
              &__details {
                width: 100%;
                display: inline-block;
              }
              &__img {
                &__container {
                  float: left;
                  width: 21%;
                }
                &__upload {
                  width: 70px;
                  height: 70px;
                }
              }
              &__welcome {
                &__container {
                  float: left;
                }
              }
            }
            .signout-link {
              font-size: 11px;
              text-decoration: underline;
              letter-spacing: 1.5px;
            }
            &__title {
              font-size: 11px;
              font-weight: normal;
              letter-spacing: 1px;
              line-height: 1;
              margin: 17px 0 0;
            }
            &__navigation {
              &-status {
                width: 100%;
                display: inline-block;
                .seedling-status,
                .sapling-status,
                .sycamore-status {
                  width: 31%;
                  height: 6px;
                  display: inline-block;
                  margin-right: 5px;
                  background-color: $color-green;
                  opacity: 0.4;
                  &.selected {
                    opacity: 1;
                  }
                }
                .seedling-name,
                .sapling-name,
                .sycamore-name {
                  font-size: 12px;
                  font-weight: normal;
                  color: $color-green;
                  font-family: $font--futura-demi;
                  display: inline-block;
                  width: 31%;
                  opacity: 0.4;
                  letter-spacing: 0;
                  text-transform: uppercase;
                  &.selected {
                    opacity: 1;
                  }
                }
                .sapling-name,
                .sycamore-name {
                  margin-left: 5px;
                }
              }
            }
          }
          .level {
            &-title {
              font-size: 13px;
              font-weight: normal;
              letter-spacing: 1px;
            }
            &-value {
              font-size: 22px;
              font-family: $font--futura-medium;
              color: $color-green;
              opacity: 0.8;
            }
          }
          .navigation-menu {
            padding: 15px;
            &__item {
              margin-bottom: 20px;
              font-size: 12px;
              letter-spacing: 0.5px;
              &.navigation {
                &__my__account,
                &__account--loyalty {
                  color: $color-drak-black;
                  text-transform: uppercase;
                  font-size: 3.3vw;
                  letter-spacing: 1px;
                  font-family: $font--futura-medium;
                }
                &__account--loyalty {
                  margin-top: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  .utility-nav-active {
    &.gnav-active {
      .page-header {
        @include breakpoint($landscape-up) {
          z-index: initial;
        }
        z-index: 21;
      }
    }
  }
  .loyalty-container {
    .hero-banner--text {
      &-no-image {
        @include breakpoint($medium-up) {
          min-height: 360px;
        }
        min-height: 238px;
      }
    }
    .hero-banner__text {
      .hero-banner {
        &__title {
          @include breakpoint($medium-up) {
            font-size: 28px;
          }
          font-size: 20px;
          font-family: $font--futura-demi;
          font-weight: bold;
          letter-spacing: 1px;
        }
      }
    }
  }
}
