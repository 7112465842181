div.sitemap {
  margin: 10px 0 20px 10%;
  .sitemap_content {
    width: 100%;
    @include breakpoint($landscape-up) {
      height: 1350px;
    }
    ul.sitemap_content.copy {
      @include breakpoint($landscape-up) {
        float: left;
        width: 32%;
        display: inline;
      }
      display: block;
      li {
        line-height: 30px;
      }
      a {
        text-decoration: none;
      }
      a:hover {
        color: $color-dark-gray;
        text-decoration: none;
      }
      a:focus {
        outline: 3px solid rgba(131, 192, 253, 0.5);
      }
    }
    .offset_height:before {
      display: block;
      content: ' ';
      height: 105px;
      margin-top: -105px;
      visibility: hidden;
    }
  }
  .subhead {
    font-size: 18px;
    font-family: $font--futura-medium;
    padding: 20px 0px 10px;
    line-height: 22px;
    font-style: italic;
  }
}

.invisible.focusable {
  @include t2;
  text-shadow: none;
  color: $color-black;
  text-decoration: none;
  visibility: visible;
}

body.section-customer-service-accessibility {
  .content-container {
    .expando-block {
      @include breakpoint($small-down) {
        border-bottom: none;
      }
      &__content {
        @include breakpoint($small-down) {
          height: auto;
        }
        p.access_para {
          margin-top: 20px;
        }
        ul.access_list {
          list-style: disc;
          padding: 0 0 0 20px;
        }
      }
    }
  }
}
