@mixin blog {
  ///////////////////////////////
  ///
  /// ===== Blog Styling =====
  ///
  /// 1. Blog Wrapper
  /// 2. Blog Header Navigation
  /// 3. Blog Sidebar
  /// 4. Blog Post
  /// 5. Blog Post Footer
  /// 6. Blog Post Carousels
  /// 7. Social Share
  /// 8. Blog Homepage
  ///
  ///////////////////////////////

  // Breakpoint reference
  //$xxsmall-up: 321px;
  //$xsmall-up:  481px;
  //$small-up:   641px;
  //$medium-up:  768px;
  //$large-up:   1025px;
  //$largest-up: 1281px;
  //$xxlarge-up: 1921px;

  // Variables
  $inner_pad: 14.4%;
  $blog_body_lineheight: 1.9em;
  $blog_body_fontsize: 19px;
  $blog_green: $color-light-green;

  /*
 *
 * 1. Blog Wrapper
 *
*/

  .blog-wrapper {
    overflow: hidden;
    &__top-bar {
      //overflow: hidden;
    }
    &__top-bar-img {
      height: 106px;
      @include breakpoint($medium-up) {
        height: 169px;
      }
      @include breakpoint($large-up) {
        height: 211px;
      }
    }
    &__top-bar-img-logo {
      background-repeat: no-repeat;
      background-position: center center;
      background-color: transparent;
      background-size: cover;
      height: 100%;
      width: 100%;
    }
    &__content {
    }

    // content container
    .blog-detail__main {
      margin-bottom: 40px;
      @include clearfix();
      margin: 0 auto;
      overflow: hidden;
      @include breakpoint($xsmall-up) {
        padding: 12px 30px;
        max-width: 1340px;
      }
      @include breakpoint($largest-up) {
        padding: 12px 3px 0 3px;
        max-width: 1286px;
      }
    }
    // aside content
    .blog-detail__blog-nav {
      float: left;
      width: 25%;
      margin-right: -25%;
      padding-right: 35px;
      padding-left: 5%;
      padding-top: 22px;
      text-align: right;
      // mobile hidden
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }

    // blog content
    .blog-detail__content {
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      float: left;
      // mobile
      width: 100%;
      margin-left: 0;
      &-second {
        &--blockquote.blockquote--content {
          font-family: $font--text;
          line-height: 1.9em;
          font-size: 19px;
          text-align: left;
        }
      }
      @include breakpoint($small-up) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include breakpoint($medium-up) {
        padding-left: 90px;
        padding-right: 0;
        width: 85%;
        margin-left: 15%;
      }
      @include breakpoint($large-up) {
        width: 75%;
        margin-left: 25%;
      }
      p {
        max-width: 930px;
      }
      blockquote {
        font-family: $font--heading--secondary;
        text-align: center;
        margin: 0.4em 0 1em 0;
        line-height: 1.1em;
        font-size: 30px;
        quotes: '“' '”' '‘' '’';
        &:before {
          content: open-quote;
        }
        &:after {
          content: close-quote;
        }
        i,
        em {
          font-family: $font--heading--secondary--italic;
          font-style: normal;
        }
      }
      // zoom effect on all images
      img {
        transition: all 0.25s ease;
        &:hover {
          transform: scale(1.05);
        }
      }

      // =======================
      // === Video Component ===
      // =======================

      .video-module {
        .video-module__title-block {
          padding: 0;
          border-bottom: none;
        }
        .video-module__video-block {
          padding: 0;
        }
        .video-module__video-container {
          overflow: hidden;
        }
        .inline-video__play-button {
          //background-image: url("/sites/origins/themes/or_base/img/blog/blog_play_grey.png");
          background-image: url('/sites/origins/themes/or_base/img/blog/blog_play_white.png');
          background-repeat: no-repeat;
          background-position: center center;
          background-color: transparent;
          background-size: 75% 75%;
          width: 145px;
          height: 145px;
          .icon {
            display: none;
            font-size: 52px;
            margin-left: 5px;
          }
          &:before {
            border: none;
            content: none;
          }
        }
        .inline-video__screenshot {
          margin: 0;
        }
        .video-module__video-text {
          padding: 20px 0 0 0;
          text-align: left;
        }
        .video-module__video-title {
          text-transform: uppercase;
          @include font--subheading();
          letter-spacing: 0;
          font-style: normal;
          font-size: 20px;
          font-weight: normal;
          line-height: 1;
          padding: 0;
          margin: 0;
        }
        .video-module__video-description {
          text-transform: uppercase;
          @include font--subheading();
          font-size: 20px;
          letter-spacing: 0;
          max-width: 100%;
          margin: 0;
          text-align: left;
        }
        .video-module__video-links {
          text-align: left;
        }
        .video-module__link {
          text-transform: uppercase;
          text-decoration: none;
          @include font--subheading();
          font-size: 20px;
          letter-spacing: 0;
          padding: 0;
          text-align: left;
          font-weight: normal;
          line-height: 1;
          &:after {
            content: '';
          }
        } //.video-module__link
      } //.video-module
    } //.blog-detail__content

    // blog content header
    .blog-detail__content-header {
      text-align: center;
      @include breakpoint($largest-up) {
        padding-left: $inner_pad;
      }
      .social_share {
        padding: 16px 0 6px 0;
        font-size: 18px;
      }
    }
    .blog-detail__title-before {
      font-family: $font--heading--secondary;
      text-transform: uppercase;
      font-size: 19px;
      @include breakpoint($xsmall-up) {
        font-size: 24px;
      }
    }
    .blog-detail__title-text {
      margin-bottom: 23px;
      margin-top: 18px;
      font-family: $font--heading--secondary;
      font-size: 26px;
      @include breakpoint($xsmall-up) {
        font-size: 36px;
      }
      @include breakpoint($small-up) {
        font-size: 48px;
      }
      i,
      em {
        font-family: $font--heading--secondary--italic;
        font-style: normal;
      }
      // remove paragraph defaults
      p {
        margin: 0;
        padding: 0;
      }
    }
    .blog-detail__title-after {
      text-transform: uppercase;
      @include font--subheading();
      font-size: 19px;
      letter-spacing: 0;
      //margin-left: -23px; // shift left
      p {
        max-width: 990px;
        margin-bottom: 0;
        margin-top: 0;
        vertical-align: top;
        display: inline;
        vertical-align: baseline;
        font-size: 19px;
        line-height: 1em;
      }
      .blog-detail__title-author {
        @include font--text();
        font-size: 19px;
        line-height: 1em;
        font-family: $font--text--italic;
        text-transform: capitalize;
        vertical-align: baseline;
        cursor: pointer;
        display: inline;
        padding-left: 0.1em;
        // center on mobile
        @include breakpoint($xxsmall-down) {
          display: block;
          padding-left: 0;
        }
        // checkbox enabled class
        &--centered {
          display: block;
        }
        &:hover {
          color: $color-black;
        }
      }
    } //.blog-detail__title-after
    .blog-detail__social-share {
      text-transform: capitalize;
      font-size: 16px;
      margin-left: -20px;
      margin-right: -20px;
      @media screen and (min-width: 352px) {
        font-size: 19px;
        margin: 0;
      }
    }
    .blog-detail__break {
      max-width: 286px;
      border-width: 0;
      border-top: 1px solid #b1b1b1;
      margin: 24px auto;
    }
    .blog-detail__content-first {
      line-height: $blog_body_lineheight;
      font-size: $blog_body_fontsize;
      @include breakpoint($largest-up) {
        padding-left: $inner_pad;
      }
    }

    // generic wrapper contains 2 columns
    .blog-detail__wrapper {
      @include clearfix();
      // default text column
      .blog-detail__content-second {
        line-height: $blog_body_lineheight;
        font-size: $blog_body_fontsize;
      }
      // two column
      &.has--content-first-extra {
        // could be a carousel, image or video
        .blog-detail__content-first-extra {
          overflow: hidden;
          margin-bottom: 20px;
          @include breakpoint($medium-up) {
            width: 50%;
            float: left;
            padding: 10px 20px 10px 0;
          }
        }
        // contains rich text
        .blog-detail__content-second {
          @include breakpoint($large-up) {
            padding-left: $inner_pad;
          }
        }
      }
      // single column
      /*
    &.is--full-width {
      width: 100%;
      @include breakpoint($largest-up) {
        padding-left: $inner_pad;
      }
    }
    */
    } //.blog-detail__wrapper

    .blog-detail__content-second-extra {
      @include clearfix();
      @include breakpoint($medium-up) {
        margin-bottom: 50px;
      }
    }
    .blog-detail__content-third {
      line-height: $blog_body_lineheight;
      font-size: $blog_body_fontsize;
      @include breakpoint($largest-up) {
        padding-left: $inner_pad;
      }
    }
    .blog-detail__content-third-image {
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
      }
      @include breakpoint($medium-up) {
        width: 50%;
        float: left;
        margin-right: 45px;
        img {
          width: auto;
        }
      }
    }
    .blog-detail__content-third-image-caption {
      @include font--subheading();
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 0;
      padding-top: 20px;
      background-color: $color-white;
      position: relative;
      display: block;
      p {
        margin: 0;
      }
      i,
      em {
        font-family: $font--heading--secondary--italic;
        font-style: normal;
      }
      a {
        text-decoration: underline;
      }
    }
    .blog-detail__content-fourth {
      line-height: $blog_body_lineheight;
      font-size: $blog_body_fontsize;
      @include breakpoint($large-up) {
        padding-left: $inner_pad;
      }
    }

    //  content bottom footer
    .blog-detail__footer-top {
      @include clearfix();
      margin-bottom: 30px;
      padding-top: 30px;
      padding-bottom: 20px;
      border-bottom: 1px solid black;
      position: relative;
      @include breakpoint($medium-up) {
        width: 85%;
        margin-left: 15%;
        float: left;
        padding-left: 5px;
        padding-right: 74px;
        border-bottom: none;
      }
      @include breakpoint($large-up) {
        width: 75%;
        margin-left: 25%;
      }
      .blog-detail__social-share {
        text-align: center;
        font-size: 15px;
        @include breakpoint($xsmall-up) {
          font-size: 19px;
        }
        // only show on desktop
        display: none;
        @include breakpoint($medium-up) {
          display: block;
          padding-left: 27px;
          width: 33%;
          float: left;
        }
      }
      .blog-detail__title-author {
        @include font--subheading();
        text-transform: uppercase;
        line-height: 18px;
        font-size: 18px;
        text-align: center;
        letter-spacing: 0;
        font-family: $font--futura-medium;
        cursor: pointer;
        display: inline-block;
        width: 100%;
        &:hover {
          color: $color-gray;
        }
        @include breakpoint($medium-up) {
          width: 33%;
          float: left;
          padding-left: 14.7%;
          text-align: left;
        }
      }
      .blog-detail__date {
        text-align: center;
        letter-spacing: -0.06em;
        font-size: 21px;
        @include breakpoint($medium-up) {
          width: 33%;
          //margin
          float: left;
          text-align: right;
        }
        // only show on desktop
        display: none;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
      .back-to-top-mobile {
        display: none;
        // only show on mobile
        @include breakpoint($small-down) {
          background: url('/sites/origins/themes/or_base/img/blog/back-top-mobile.png') no-repeat center center;
          display: block;
          width: 30px;
          height: 30px;
          margin: 0 auto;
          padding-top: 70px;
        }
      }
    } //.blog-detail__footer-top
  } //.blog-wrapper

  /*
 *
 * 2. Blog Header Navigation
 *
*/

  .blog-navigation {
    padding: 8px 0 40px 0;
    position: relative;
    // border elements
    &:before {
      background: url('/sites/origins/themes/or_base/img/blog/blog-nav-header-left-g.png') no-repeat center center;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: -0.9em;
      bottom: 0;
      z-index: -1;
      left: -94%;
      @include breakpoint($xsmall-up) {
        left: -89%;
      }
      @include breakpoint($small-up) {
        top: -0.9em;
        left: -82%;
      }
      @include breakpoint($medium-up) {
        left: -600px;
      }
    }
    &:after {
      background: url('/sites/origins/themes/or_base/img/blog/blog-nav-header-right-g.png') no-repeat center center;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: -0.9em;
      bottom: 0;
      z-index: -1;
      right: -94%;
      @include breakpoint($xsmall-up) {
        right: -89%;
      }
      @include breakpoint($small-up) {
        top: -0.9em;
        right: -82%;
      }
      @include breakpoint($medium-up) {
        right: -600px;
      }
    }
    // link typography
    a {
      @include font--subheading();
      font-size: 18px;
      @media screen and (min-width: 352px) {
        font-size: 20px;
      }
      @media screen and (min-width: 421px) {
        font-size: 23px;
      }
      transition: color, 200ms;
      &:hover {
        color: $color-dark-gray;
        text-decoration: none;
      }
    }

    // top level dropdown links
    span.menu__link--lvl-1 {
      color: $blog_green;
    }

    // dropdown expand
    .menu__link--lvl-1 {
      @include font--subheading();
      font-size: 18px;
      @media screen and (min-width: 352px) {
        font-size: 20px;
      }
      @media screen and (min-width: 421px) {
        font-size: 23px;
      }
      transition: color, 200ms;
      cursor: pointer;
      &:hover {
        color: $blog_green;
        text-decoration: none;
      }
      .expanded & {
        text-decoration: none;
      }
    }

    // top level ul element
    .menu__main--lvl-1 {
      text-align: center;
      text-transform: uppercase;
      display: block;
      // all li elements
      & > li {
        display: inline-block;
      }
    } //.menu__main--lvl-1

    // top lvl li elements
    .menu__main--lvl-1 > .menu__item--lvl-1 {
      padding-right: 2%;
      padding-left: 2%;
      @include breakpoint($small-up) {
        padding-right: 3%;
        padding-left: 3%;
      }
      @include breakpoint($medium-up) {
        padding-right: 1.76%;
        padding-left: 1.76%;
        position: relative;
      }
    }

    // sub ul elements
    .menu__main--lvl-2 {
      display: none;
      transition: opacity, 300ms;
      position: absolute;
      z-index: 1;
      top: initial;
      background-color: $color-white;
      // mobile
      margin-left: 0;
      top: 100%;
      width: 100%;
      left: 0;
      @include breakpoint($medium-up) {
        top: 35px;
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
      }
      // sub ul li elements
      li {
        padding: 0;
        // mobile
        display: block;
        @include breakpoint($medium-up) {
          display: table-cell;
          white-space: nowrap;
          text-align: center;
        }
        a {
          font-size: 18px;
          @media screen and (min-width: 352px) {
            font-size: 20px;
          }
          @media screen and (min-width: 421px) {
            font-size: 23px;
          }
          letter-spacing: 0;
          display: inline-block;
          // mobile
          padding: 0 15px 25px 15px;
          @include breakpoint($medium-up) {
            padding: 0 15px;
          }
        }
      } //li
    } //.menu__main--lvl-2

    // show expanded and color hover
    .expanded .menu__main--lvl-2 {
      display: block;
      z-index: 2;
    }

    // active menu item color
    .menu__link.active {
      color: $blog_green;
    }
  } //.blog-navigation

  /*
 *
 * 3. Blog Sidebar
 *
*/

  .blog-sidebar {
    margin-top: 80px;
    overflow: hidden;
    padding-right: 6px;

    // hide all
    .blog-sidebar__wrapper {
      //overflow: hidden;
      max-height: 500px;
      transition: all 400ms;
    }
    // hide top ones that are not active
    .blog-sidebar__top .blog-sidebar__wrapper {
      max-height: 0;
      visibility: hidden;
      opacity: 0;
    }
    // show bottom ones that are not active
    .blog-sidebar__bottom .blog-sidebar__wrapper {
      max-height: 500px;
      visibility: visible;
      opacity: 1;
    }

    // conditionally show/hide
    &.menu-archives {
      .blog-sidebar__top .menu-archives {
        max-height: 500px;
        visibility: visible;
        opacity: 1;
      }
      .blog-sidebar__bottom .menu-archives {
        max-height: 0;
        visibility: hidden;
        opacity: 0;
      }
    }
    &.menu-authors {
      .blog-sidebar__top .menu-authors {
        max-height: 500px;
        visibility: visible;
        opacity: 1;
      }
      .blog-sidebar__bottom .menu-authors {
        max-height: 0;
        visibility: hidden;
        opacity: 0;
      }
    }
    &.menu-section {
      .blog-sidebar__top .menu-section {
        max-height: 500px;
        visibility: visible;
        opacity: 1;
      }
      .blog-sidebar__bottom .menu-section {
        max-height: 0;
        visibility: hidden;
        opacity: 0;
      }
    }

    // line decoration
    &:before {
      content: '';
      display: block;
      border-top: 6px solid black;
      padding-bottom: 42px;
    }
    a {
      font-family: $font--text--italic;
      font-size: 19px;
      line-height: 1.1em;
      padding-bottom: 8px;
    }
    //.blog-sidebar__break
    hr {
      margin: 57px 0 20px 0;
      border-width: 0;
      border-top: 1px solid #b1b1b1;
    }
    //replaceable section
    .blog-sidebar__current-menu {
      padding-top: 42px;
    }
    .blog-sidebar__titles-list {
      //min-height: 40px;
      margin-bottom: 20px;
      transition: all 0.75s ease;
      a {
        display: block;
        padding-bottom: 20px;
      }
      .is_active a {
        color: $blog_green;
      }
      // li:first-child a {
      //   color: $blog_green;
      // }
    }
    //bottom
    .blog-sidebar__wrapper {
      padding-top: 4px;
      span.blog-sidebar__label {
        text-transform: uppercase;
        @include font--subheading();
        font-size: 30px;
        letter-spacing: 0;
        transition: color, 200ms;
        font-family: $font--futura-medium;
        margin-bottom: 10px;
        margin-top: 10px;
        //padding-bottom: 5px;
        display: block;
      }
      // shared class for span and list item
      .menu__link--lvl-1 {
        font-family: $font--text--italic;
        font-size: 18px;
        line-height: 1.1em;
        padding-bottom: 3px;
        display: block;
        cursor: pointer;

        // disable highlighting text
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        &::selection,
        ::-moz-selection {
          color: $color-black;
          background-color: transparent;
        }
      } // .menu__link--lvl-1

      // applied to months, authors
      a.menu__link--lvl-1 {
        color: $color-gray;
        position: relative;
        display: block;
      } // span.menu__link--lvl-1

      // applies to months, authors, post links
      a.menu__link--lvl-1,
      a {
        &:hover {
          color: $color-black;
        }
      }

      // current author and month/year are highlighted
      .is_active a.menu__link--lvl-1 {
        //color: $color-black;
        color: $blog_green;
        &:hover {
          color: $color-black;
        }
      }

      // hide titles
      //ul.section__main--lvl-1 ul,
      ul.archives__main--lvl-1 ul,
      ul.authors__main--lvl-1 ul {
        display: none;
      }

      // Section behaves differently
      &.menu-section {
        // subsection title not links // TODO simplify selector
        .section__main.section__main--lvl-1 > .menu__item > a.menu__link--lvl-1 {
          text-transform: uppercase;
          @include font--subheading();
          font-size: 30px;
          letter-spacing: 0;
          font-family: $font--futura-medium;
          margin-bottom: 10px;
          margin-top: 10px;
          display: block;
          font-style: normal;
          cursor: pointer;
          color: $color-black;
          .is_active {
            color: $color-black;
          }
        }
        .section__main--lvl-2 a {
          display: inline-block;
          padding-bottom: 20px;
          color: $color-gray;
        }
        // current post link
        .menu__item .is_active a {
          color: $blog_green;
        }
      }
      // Hide post links in sections
      ul.section__main--lvl-2 {
        display: none;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        height: 0;
      }
      // Show post links in sections
      .is_active ul.section__main--lvl-2 {
        display: block;
        height: auto;
      }
    } //.blog-sidebar__wrapper

    // never show links in menu in blog sidebar bottom (also ignore active class)
    .blog-sidebar__bottom ul.section__main--lvl-2,
    .blog-sidebar__bottom .menu__item.is_active ul.section__main--lvl-2,
    .blog-sidebar__bottom ul.section__main--lvl-2 a {
      display: none;
    }

    // editors
    .blog-sidebar__editors-note {
      margin-bottom: 10px;
      margin-top: 10px;
      display: block;
      a {
        text-transform: uppercase;
        @include font--subheading();
        font-size: 30px;
        letter-spacing: 0;
        font-family: $font--futura-medium;
        font-style: normal;
        line-height: 1.1em;
        padding-bottom: 8px;
        color: $color-gray;
      }
      a:hover {
        color: $color-black;
        text-decoration: none;
      }
      &.is_active a {
        color: $color-black;
        text-decoration: none;
      }
    }
  } //.blog-sidebar

  /*
 *
 * 4. Blog Post
 *
*/

  .blog-post {
    overflow: hidden;
    &__hero-img {
      overflow: hidden;
      img {
        width: 100%; //stretch to full width
        transition: all 0.25s ease;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    // links
    a {
      &:hover {
        color: $color-gray;
        text-decoration: none;
      }
    }
    // use italic font-family in content
    i,
    em {
      font-family: $font--text--italic;
      font-style: normal;
    }
    // use italic font family in headings
    h1,
    h2,
    h3 {
      i,
      em {
        font-family: $font--heading--secondary--italic;
        font-style: normal;
      }
    }
  } //.blog-post

  /*
 *
 * 5. Blog Post Footer
 *
*/

  // page footer
  .blog-detail__footer {
    @include clearfix();
    margin-bottom: 70px;
    padding-left: 10px;
    padding-right: 10px;
    @include breakpoint($small-up) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include breakpoint($medium-up) {
      width: 75%;
      margin-left: 25%;
      float: left;
      padding-left: 10px;
      padding-right: 50px;
    }
  }
  .blog-nav-footer {
    border-top: 35px solid black;
    padding-top: 10px;
    overflow: hidden;
  }
  .blog-nav-footer__title {
    font-family: $font--heading--secondary;
    text-align: center;
    font-size: 55px;
    font-weight: normal;
    margin-top: 50px;
    margin-bottom: 16px;
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    i,
    em {
      font-family: $font--heading--secondary--italic;
      font-style: normal;
    }
  }
  .blog-nav-footer__slot_label {
    @include font--subheading();
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    // hide on mobile
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .blog-nav-footer__slots {
    @include clearfix();
    overflow: hidden;
  }
  .blog-nav-footer__slot {
    float: left;
    width: 50%;
    @include breakpoint($medium-up) {
      width: 20%;
    }
  }
  .blog-nav-footer__img {
    overflow: hidden;
    height: 1px;
    padding-top: 76%;
    position: relative;
    @include breakpoint($medium-up) {
      height: auto;
      padding-top: 0;
      position: static;
    }
    img {
      width: 100%; // allow wider images
      transition: all 0.25s ease;
      position: absolute;
      top: 0;
      @include breakpoint($medium-up) {
        position: static;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .blog-nav-footer__slot_text {
    text-align: center;
    font-size: 22px;
    min-height: 84px;
    @include breakpoint($xsmall-up) {
      min-height: auto;
      padding: 0 15px;
    }
  }

  /*
 *
 * 6. Blog Post Carousels
 *
*/

  .blog-carousel-formatter {
    position: relative;
    .carousel-controls {
      // adjust button position for caption
      top: calc(50% - 72px);
      // change icons
      .slick-prev:before {
        content: '';
        background: url('/sites/origins/themes/or_base/img/blog/blog_carousel_left.png') no-repeat center center;
        left: -5px;
      }
      .slick-next:before {
        content: '';
        background: url('/sites/origins/themes/or_base/img/blog/blog_carousel_right.png') no-repeat center center;
        right: -5px;
      }
    }
    .basic-image {
      overflow: hidden;
      display: block;
    }
    .basic-image__caption {
      position: relative;
      bottom: 0;
      z-index: 9;
      background-color: $color-white;
      i,
      em {
        font-family: $font--heading--secondary--italic;
        font-style: normal;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    // image captions
    .basic-image__caption {
      text-transform: uppercase;
      @include font--subheading();
      font-size: 20px;
      letter-spacing: 0;
      //height: 120px;
      overflow: hidden;
      display: block;
      padding-top: 20px;
      a {
        text-decoration: underline;
      }
    }
  } //.blog-carousel-formatter

  /*
 *
 * 7. social_share
 *
*/

  .social_share {
  }
  .social_share__item:after {
    content: ' . ';
    color: $color-black;
  }
  .social_share_item--message-wrapper {
    // hide on desktop
    .device-pc & {
      display: none;
    }
  }

  /*
 *
 * 8. Blog Homepage
 *
*/

  .blog-home {
    overflow: hidden;
    a {
      &:hover {
        color: $color-gray;
        text-decoration: none;
      }
    }
    // use italic font-family in content
    i,
    em {
      font-family: $font--text--italic;
      font-style: normal;
    }
    // use italic font family in headings
    h1,
    h2,
    h3 {
      i,
      em {
        font-family: $font--heading--secondary--italic;
        font-style: normal;
      }
    }
    /// columns container
    &__col-cont-outer {
      // content container
      //background-image: url("/sites/origins/themes/or_base/img/blog/blog-home-watercolor-top.jpg");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-color: transparent;
      width: 100%;
    }
    /// columns container
    &__col-cont {
      @include clearfix();
      overflow: hidden;
      padding: 0;
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      @include breakpoint($medium-up) {
        margin-bottom: 10px;
        max-width: 550px; // single column
      }
      @include breakpoint($large-up) {
        max-width: 1280px;
      }
    }

    // column first right
    &__col-lg {
      padding-top: 67px;
      padding-left: 0;
      padding-right: 0;
      float: right;
      // mobile
      width: 100%;
      margin-left: 0;
      @include breakpoint($large-up) {
        //padding-top: 67px;
        padding-left: 17px;
        padding-right: 20px;
        width: 56%;
      }
      @include breakpoint($largest-up) {
        padding-right: 0;
      }
      // zoom effect on all images
      img {
        transition: all 0.25s ease;
        &:hover {
          transform: scale(1.05);
        }
      }
    } //__col-lg

    // column second left
    &__col-sm {
      float: right;
      text-align: right;
      width: 100%;
      @include breakpoint($large-up) {
        width: 44%;
        padding-right: 43px;
        padding-left: 20px;
        padding-top: 66px;
      }
      @include breakpoint($largest-up) {
        padding-top: 0;
        padding-left: 0;
      }
    } //__col-sm

    // ---------------------
    // --- slot defaults ---
    // ---------------------

    .blog-home__slot-label {
      @include font--subheading();
      text-transform: uppercase;
      margin: 0 0 14px 0;
      letter-spacing: 0px;
      text-align: left;
      font-size: 20px;
      @include breakpoint($small-up) {
        font-size: 25px;
      }
      @include breakpoint($medium-up) {
        text-align: center;
        //font-size: 32px;
      }
    }
    .blog-home__slot-img {
      display: block;
      overflow: hidden;
      position: relative;
      // default mobile
      float: left;
      width: 50%;
      @include breakpoint($medium-up) {
        height: auto;
        padding-top: 0;
        float: none;
        width: 100%;
        // hover effect
        img {
          //position: static;
          width: 100%; //stretch to full width
          transition: all 0.25s ease;
          &:hover {
            transform: scale(1.05);
          }
        } //img
      }
    } //.blog-home__slot-img

    .blog-home__slot-content {
      text-align: center;
      // default mobile
      float: left;
      width: 50%;
      padding-left: 20px;
      text-align: left;
      @include breakpoint($medium-up) {
        padding-left: 40px;
        float: none;
        width: 100%;
        padding-left: 0;
        text-align: center;
      }
    }
    .blog-home__slot-title {
      font-family: $font--heading--secondary;
      font-weight: normal;
      margin: 0px;
      display: inline-block;
      p {
        margin: 0;
        display: inline-block;
      }
      i,
      em {
        font-family: $font--heading--secondary--italic;
        font-style: normal;
        display: inline-block;
      }
    }
    .back-to-top-mobile {
      // only show on mobile
      @include breakpoint($medium-down) {
        background: url('/sites/origins/themes/or_base/img/blog/back-top-mobile.png') no-repeat center center;
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: 40px;
        z-index: 3;
      }
    }
    .blog-home__slot-text {
      font-family: $font--heading--secondary;
      p {
        margin: 0;
      }
      i,
      em {
        font-family: $font--heading--secondary--italic;
        font-style: normal;
      }
      //mobile hidden
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        padding-bottom: 2px;
      }
    }
    .blog-home__slot-inner {
      overflow: hidden;
    }

    // ---------------------
    // slot small defaults
    // ---------------------
    .blog-home__slot-small {
      padding: 32px 0 0 0;
      position: relative;
      .blog-home__slot-content {
        padding-top: 10px;
      }
      .blog-home__slot-title {
        font-size: 28px;
      }
      .blog-home__slot-text {
        padding-top: 1px;
        font-size: 18px;
      }
    }

    // --------------------------
    // Individual Slot default overrides
    // --------------------------

    // first top post slot
    .blog-home__slot1 {
      margin-top: 5px;
      .blog-home__slot-img {
        //float: none;
        width: 100%;
        height: auto;
        padding-top: 0;
        padding-bottom: 12px;
        @include breakpoint($medium-up) {
          padding-bottom: 25px;
        }
      }
      .blog-home__slot-content {
        text-align: left;
        padding-right: 10px;
        padding-left: 10px;
        float: none;
        width: 100%;
        @include breakpoint($medium-up) {
          float: none;
          width: 100%;
          padding-right: 20px;
          padding-left: 20px;
          max-width: 1280px;
          margin: 0 auto;
        }
        @include breakpoint($largest-up) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .blog-home__slot-title {
        font-size: 26px;
        text-align: center;
        line-height: 1.45em;
        @include breakpoint($xsmall-up) {
          font-size: 36px;
        }
        @include breakpoint($small-up) {
          font-size: 50px;
          text-align: left;
        }
        @include breakpoint($medium-up) {
          font-size: 60px;
        }
      }
      .blog-home__slot-label {
        //font-size: 32px;
        margin: 10px 0 12px 0;
        //font-weight: bold;
        letter-spacing: 0.4px;
        text-align: left;
        // mobile hidden
        display: none;
        @include breakpoint($medium-up) {
          display: block;
          float: right;
          margin: 23px 60px 0 0;
        }
      }
      .blog-home__slot-text {
        font-size: 21px;
        padding-top: 4px;
      }
    } //.blog-home__slot1

    // slots 2-4 are smaller and appear in the left column
    .blog-home__slot2 {
      margin-top: 48px;
      border-top: 1px solid black;
      padding: 40px 10px 0 10px;
      @include breakpoint($medium-up) {
        margin-top: 0;
        border-top: none;
        padding: 0 0 0 0;
      }
      @include breakpoint($largest-up) {
        padding: 63px 140px 0 0;
      }
    }
    // slots 3 and 4 share the same row on mobile
    .blog-home__slot3 {
      padding: 32px 10px 0 10px;
      @include breakpoint($medium-up) {
        padding: 28px 0 0 0;
      }
      @include breakpoint($largest-up) {
        padding: 28px 0 0 140px;
      }
      // allow for two lines
      .blog-home__slot-label {
        min-height: 40px;
      }
    }
    .blog-home__slot4 {
      padding: 32px 10px 0 10px;
      @include breakpoint($medium-up) {
        padding: 28px 0 0 0;
      }
      @include breakpoint($largest-up) {
        padding: 28px 140px 0 0;
      }
      // allow for two lines
      .blog-home__slot-label {
        min-height: 40px;
      }
    }

    // slot 5 is larger and appears on the right side on desktop
    // on mobile slot 5 appears first
    .blog-home__slot5 {
      padding-left: 10px;
      padding-right: 10px;
      border-top: 1px solid black;
      padding-top: 35px;
      position: relative;
      @include breakpoint($medium-up) {
        padding: 0;
        border-top: none;
      }
      .blog-home__slot-content {
        text-align: left;
        padding-top: 37px;
      }
      .blog-home__slot-title {
        font-size: 28px;
        @include breakpoint($small-up) {
          font-size: 48px;
        }
        @include breakpoint($medium-up) {
          font-size: 52px;
        }
      }
      .blog-home__slot-label {
        //.pc-hidden
        margin: 10px 0 14px 0;
        font-weight: normal;
        letter-spacing: 0.4px;
        text-align: left;
        //font-size: 22px;
        //font-size: 26px;
        &.blog-mobile-only {
          display: block;
        }
        &.blog-desktop-only {
          display: none;
        }
        @include breakpoint($medium-up) {
          //font-size: 32px;
          display: inline-block;
          float: right;
          margin: 6px 40px 14px 0;
          &.blog-mobile-only {
            display: none;
          }
          &.blog-desktop-only {
            display: inline-block;
          }
        }
      }
      .blog-home__slot-text {
        font-size: 18px;
        padding-top: 6px;
        line-height: 1.1em;
      }
    } //.slot-home__slot5

    // mobile specific changes
    // slots 2 and 3 appear side by side on mobile
    @include breakpoint($small-down) {
      border-bottom: 1px solid black;
      .blog-home__mobile-wrapper {
        overflow: hidden;
        margin-top: 47px;
        padding-top: 8px;
        border-top: 1px solid black;
        padding-bottom: 50px;
        margin-bottom: 0px;
        border-bottom: 1px solid black;
        .blog-home__slot-small {
          width: 50%;
          //padding-right: 10px;
          float: left;
          .blog-home__slot-content {
            width: 100%;
            max-width: 330px;
            float: none;
            padding-left: 0;
          }
          .blog-home__slot-title {
            //font-size: 32px;
            font-size: 20px;
          }
          .blog-home__slot-img {
            width: 100%;
            float: none;
          }
        }
      }
      // hide image for mobile
      .blog-home__slot-small img {
        display: none;
      }
      .blog-home__slot5 img {
        display: none;
      }
      // show as a background image instead
      .blog-home__slot-mobile-img {
        height: 1px;
        width: 100%;
        padding-top: 100%;
        @include breakpoint($small-up) {
          height: 330px;
          width: 330px;
          padding: 0;
        }
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        float: left;
      }
    } // mobile

    // bottom section
    .blog-home__footer-top {
      //background-image: url("/sites/origins/themes/or_base/img/blog/blog-home-watercolor-middle.jpg");
      background-repeat: no-repeat;
      background-position: center top;
      background-color: transparent;
      background-size: 100% auto;
      .blog-home__footer-top-inner {
        text-align: center;
        padding: 44px 20px 60px 20px;
        @include breakpoint($medium-up) {
          padding: 44px 14% 20px 14%;
        }
      }
      .blog-home__text_small,
      .blog-home__text_small .text-header__subtitle {
        font-size: 28px;
        @include breakpoint($small-up) {
          font-size: 46px;
        }
        @include breakpoint($medium-up) {
          font-size: 55px;
        }
      }
      .blog-home__text_small .text-header {
        padding: 21px 0 1px 0;
        @include breakpoint($small-up) {
          padding: 41px 0 1px 0;
        }
        @include breakpoint($medium-up) {
          padding: 24px 0 7px 0;
        }
      }
      hr {
        border-width: 0;
        border-top: 1px solid #b1b1b1;
        max-width: 80px;
        margin: 10px auto 10px auto;
        @include breakpoint($medium-up) {
          margin: 20px auto;
          margin: 20px auto 20px auto;
          max-width: 175px;
        }
      }
      .blog-home__text_large,
      .blog-home__text_large .text-header__subtitle {
        font-weight: normal;
        line-height: 1em;
        display: inline-block;
        //padding-top: 13px;
        font-size: 28px;
        @include breakpoint($small-up) {
          font-size: 55px;
        }
        @include breakpoint($medium-up) {
          padding-top: 40px;
          font-size: 72px;
        }
      }
      .blog-home__text_large .text-header {
        padding: 0 0 0 0;
      }
    } //.blog-home__footer-top

    // blog footer
    .blog-home__footer {
      // carousel goes here
      //background-image: url("/sites/origins/themes/or_base/img/blog/blog-home-watercolor-bottom.jpg");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-color: transparent;
      //background-size: 100% auto;
      padding: 40px 0 40px 0;
      @include breakpoint($small-up) {
        padding: 125px 0 252px 0;
      }
    }
    .blog-home__footer-inner {
      max-width: 1444px;
      margin: 0 auto;
      overflow: hidden;
    }
  } //.blog-home

  // Editors Note static page menu item
  body.section-editors-note .blog-sidebar_editors-note-link {
    color: $color-black;
  }

  //////////////////
} //mixin

$blog: false !default;

@if $blog == true {
  @include blog;
}
