#recommended-products-panel {
  .recommended-item {
    @include breakpoint($medium-up) {
      text-align: left;
    }
    &__product-image {
      &,
      &__image {
        @include breakpoint($medium-up) {
          max-height: 300px;
        }
      }
    }
    &__product {
      &-info {
        &__subname {
          @include breakpoint($medium-up) {
            letter-spacing: 0;
            padding-bottom: 0;
          }
        }
        &__benefit-label {
          @include breakpoint($medium-up) {
            font-family: $font--futura-medium;
          }
        }
        &__price {
          @include breakpoint($medium-up) {
            line-height: 2;
            display: inline-block;
            min-width: 76px;
          }
        }
        &__review-snippet {
          padding: 5px 0;
        }
      }
    }
    &__cta {
      display: table-cell;
      &-spp-link {
        @include secondary-hover;
        vertical-align: bottom;
        display: table-cell;
        padding-left: 1em;
        text-decoration: underline;
      }
    }
    .addtobag_cta {
      @include breakpoint($medium-up) {
        border-style: none;
      }
    }
    .sku-list-sizes {
      display: inline-block;
    }
    .product-sku-price__value {
      font-size: inherit;
    }
    &__product-image__image {
      &.product-image__image--alt {
        opacity: 0;
        position: absolute;
        top: 52px;
        max-height: 258px;
        transition: opacity 0.3s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .product-brief {
    &__shades {
      @include breakpoint($medium-up) {
        max-width: 150px;
        min-width: 150px;
        .slick-next {
          left: 157px;
        }
      }
      &.slick-slider {
        float: right;
        margin: 15px;
      }
    }
    &__shade {
      height: 22px;
      width: 22px;
    }
  }
}
