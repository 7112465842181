.site-email-signup {
  &--promotion {
    max-width: 85%;
    @include breakpoint($medium-up) {
      max-width: 98%;
    }
    .site-email-signup {
      &__fields {
        width: max-content;
        @include breakpoint($medium-up) {
          display: flex;
          justify-content: center;
        }
        .email-field {
          margin-bottom: 30px;
          @include breakpoint($medium-up) {
            margin-bottom: 0;
          }
        }
      }
      &__terms-conditions__checkbox {
        @include breakpoint($medium-up) {
          margin: 0 70px 20px;
        }
      }
      &__success {
        padding-top: 36px;
        @include breakpoint($medium-up) {
          height: inherit;
        }
      }
    }
  }
}

.popup-content {
  height: 220px !important;
}

#cboxOverlay {
  &.promotion-signup-thanks {
    background-color: $color-white;
  }
}
