#account_chat {
  display: inline-block;
}

.device-mobile {
  #careers_chat {
    font-size: 14px;
    font-family: $font--futura-book;
  }
  .gnav-util--account .menu__link--lvl-1 {
    padding-top: 2px;
  }
}

.lp_survey_view_container {
  .lp_pages_area {
    .lp_radiobutton_wrapper {
      input[type='radio'] {
        display: none;
      }
    }
  }
}

.lp_select_field {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  text-transform: capitalize;
}

.signin-block__authed__status a {
  text-decoration: none;
  display: block;
}

.at-p {
  font-size: 32px;
  line-height: 38.4px;
  font-weight: normal;
}

.hero-large__text p,
h2 {
  margin: 0;
}

.at-h3-loyalty {
  font-size: 1.5em;
  margin: 0;
}

.giftcards-content__description-header-h3 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font--futura-demi;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: normal;
  line-height: 1;
  font-size: 24px;
  margin-bottom: 1em;
  margin-top: 0px;
}

.adpl {
  input {
    &[type='email'] + label:before,
    &[type='tel'] + label:before,
    &[type='password'] + label:before,
    &[type='text'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
  select + label:before {
    content: attr(data-required) attr(placeholder);
  }
}

#samples-panel {
  ul.product-list {
    li.product {
      a.sample-select-button {
        display: block;
      }
      &.selected {
        border: 2px solid $color-white;
      }
    }
  }
}

.page-header__nav__inner {
  .page-header__nav__supplemental {
    .gnav-util--groupby-search {
      float: none;
    }
  }
}

.opc__cart,
.opc__signin,
.opc__wp_samples {
  .checkout__content {
    #header {
      .single-message {
        color: $color-error-msg;
        a {
          color: $color-error-msg;
        }
        &[id^='error_offer_criteria_met'] {
          color: $color-success-msg;
          a {
            color: $color-success-msg;
          }
        }
        &.threshold-messages {
          color: $color-alert-msg;
          a {
            color: $color-alert-msg;
          }
        }
        &.success-message {
          color: $color-success-msg;
        }
      }
    }
  }
}

.page-wrapper {
  .page-header__nav,
  .page-header.alt-nav-mob {
    .gnav-util--search-v2.gnav-util {
      &.active-util {
        .gnav-util__content__inner form.gnav-search {
          .gnav-search--main-content {
            .gnav-search__suggestions-wrapper {
              .product-grid__content {
                .product-grid__item {
                  .product-brief__img {
                    .product-grid__content {
                      .product-grid__item {
                        padding: 10px 10px;
                        .product-brief {
                          padding: 0;
                        }
                        .product-brief__img {
                          max-width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .gnav-util__content__inner {
        form.gnav-search input[type='search'] {
          @include breakpoint($landscape-up) {
            position: fixed;
            left: auto;
            @if not is_cr20_desktop_nav_refresh() {
              @if is_2020_nav_redesign() {
                top: 73px;
                margin: 0 -5px 0 30px;
              } @else {
                top: 60px;
                margin-left: 2%;
              }
            }
          }
        }
      }
    }
  }
  .viewcart {
    margin-top: 58px;
    @include breakpoint($large-up) {
      margin-top: 0;
    }
    .checkout-header {
      &__title {
        &__count {
          display: inline-block;
          @include breakpoint($medium-up) {
            display: none;
          }
        }
      }
    }
    .checkout__panel--header {
      .pc-hidden {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
    .promo-bca-panel {
      @include breakpoint($medium-up) {
        padding: 1em;
      }
      .checkout__panel {
        &--header,
        &--content {
          padding: 0;
        }
        &--heading {
          margin-bottom: 0;
          display: block;
        }
      }
      &__description,
      &__logo {
        padding-top: 1em;
        font-size: 15px;
      }
    }
    .paypal-smart-button {
      display: flex;
      column-gap: 6px;
      width: 100%;
      @include breakpoint($medium-up) {
        height: 50px;
        column-gap: 8px;
        vertical-align: middle;
        .enhanced-cart-page & {
          margin-bottom: 10px;
          width: 100%;
        }
      }
      &.paypal-loading {
        background: url('/media/images/global/ajax-loading.gif') no-repeat center center;
        position: relative;
        text-align: center;
      }
    }
  }
  #sticky-checkout-button-overlay {
    .apple-pay-button-container {
      border: 0;
      height: 50px;
      margin: 0 0 8px;
      width: 99%;
      .apple-pay-button {
        width: 100%;
      }
    }
  }
  .checkout__panel--continue-buttons {
    .apple-pay-button-container {
      margin-bottom: 6px;
      height: 48px;
      &:hover {
        border-radius: 4px;
      }
    }
  }
  @supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button-container {
      border: 0;
      padding: 0;
      .apple-pay-button {
        cursor: pointer;
        -apple-pay-button-type: check-out;
        &:hover {
          filter: brightness(0.95);
        }
      }
    }
  }
  .rewards_icon_container {
    margin: 15px;
    .rewards--logo {
      background: url(/media/images/global/my-rewards.svg) no-repeat;
      height: 82px;
      width: 82px;
      display: inline-block;
    }
  }
  .review-panel__loyalty-points {
    &__description {
      position: relative;
      bottom: 20px;
    }
  }
}

@include breakpoint($medium-up) {
  .utility-nav-active {
    .page-header__nav {
      .gnav-util--search-v2.gnav-util.active-util {
        .gnav-util__content__inner form.gnav-search {
          display: grid;
          .gnav-search--main-content {
            .gnav-search__suggestions-wrapper {
              border-right: 0;
              display: block;
            }
            .gnav-search__content {
              border-left: 1px solid $color-light-gray;
              display: block;
            }
          }
        }
      }
    }
  }
}

#confirmation-page {
  .refer-a-friend {
    &__content {
      display: flex;
      flex-direction: column-reverse;
      @include breakpoint($medium-up) {
        align-items: center;
        background-color: $color-silver;
        flex-direction: row;
      }
      &-text {
        padding: 15px 0;
        text-align: center;
        @include breakpoint($medium-up) {
          padding: 0;
          width: 50%;
        }
        .extole_banner {
          &_text {
            font-family: $font--futura-book;
            font-size: 18px;
            padding: 0 20px;
            @include breakpoint($medium-up) {
              padding: 0 35px;
            }
          }
          &_header {
            font-family: $font--futura-demi;
            font-size: 18px;
            margin: 0;
          }
          &_title {
            font-size: 32px;
          }
        }
        .button {
          height: 35px;
          line-height: 2.34;
          a {
            text-decoration: none;
          }
        }
      }
    }
    &__pc-banner {
      width: 50%;
    }
    &__banner {
      vertical-align: middle;
    }
  }
}

.section-special-offers {
  .cta-tout__content {
    #extole_zone_offers_page {
      a {
        color: $color-white;
        text-decoration: none;
      }
    }
  }
}
.ui-datepicker-prev,
.ui-datepicker-next {
  &.ui-state-hover {
    border: 0;
  }
}
