$marketing-green: #588032;
@mixin reset-styles {
  margin: 0;
  font-size: 100%;
}

@if $loyalty-redesign == true {
  .loyalty__benefits__table {
    @include breakpoint($medium-up) {
      margin: 0 32px;
    }
    margin: 0 5.5%;
    .benefits__initial {
      @include breakpoint($medium-up) {
        width: 18%;
      }
      width: 11.5%;
    }
    .common__benefits {
      @include breakpoint($medium-up) {
        font-size: 15px;
      }
      color: $color-green;
      font-size: 11px;
      font-family: $font--futura-medium;
      letter-spacing: 0.5px;
      &.savings__text {
        @include breakpoint($medium-up) {
          width: 33%;
        }
        vertical-align: bottom;
      }
      &.header__benefits {
        @include breakpoint($medium-up) {
          width: 10%;
        }
        width: 13%;
        text-align: center;
        padding-bottom: 0;
        color: $color-drak-black;
      }
    }
    .seedling__wrapper {
      background-color: $color-light-green;
      padding: 12px 0;
    }
    .sapling__wrapper {
      background-color: $color-sapling;
      padding: 12px 0;
    }
    .sycamore__wrapper {
      background-color: $color-sycamore;
      padding: 12px 0;
    }
    .seedling__level,
    .sapling__level,
    .sycamore__level {
      @include breakpoint($medium-up) {
        padding: 7px 3.9px 15.6px;
      }
      text-align: center;
      color: $color-white;
      padding: 7px 2.2px 33px;
      .level__name {
        @include breakpoint($medium-up) {
          font-size: 20px;
        }
        font-size: 13px;
        letter-spacing: 1px;
      }
      .level__points {
        @include breakpoint($medium-up) {
          font-size: 11px;
          padding-top: 0;
        }
        font-size: 7.5px;
        padding-top: 3px;
      }
    }
    .benefits {
      &__title {
        font-family: $font--futura-demi;
        font-weight: normal;
        font-size: 24px;
        text-align: center;
        margin-bottom: 0;
        line-height: 1;
        letter-spacing: 1px;
      }
      &__subtitle {
        font-size: 13px;
        letter-spacing: 0;
        text-align: center;
        margin-top: 0;
      }
    }
    .terms__loyalty {
      @include breakpoint($medium-up) {
        text-align: center;
      }
      color: $color-mid-gray;
      font-size: 13px;
      letter-spacing: 0;
      text-align: right;
      a {
        @include secondary-hover;
        text-decoration: underline;
        color: $color-black;
      }
    }
    table {
      @include breakpoint($medium-up) {
        margin: 20px 0;
      }
      margin: 20px 0 0;
      tbody,
      thead {
        tr {
          border-bottom: 1px solid $color-light-gray;
          &.tier__details {
            padding: 12px;
            border-bottom: none;
          }
          td {
            @include breakpoint($medium-up) {
              padding: 1.2em 0.3em;
              font-size: 13px;
            }
            font-size: 11px;
            padding: 1.2em 0.2em;
            &:first-child {
              @include breakpoint($medium-up) {
                padding-left: 1.2em;
              }
              padding-left: 0.5em;
            }
            &.selected {
              @include breakpoint($medium-up) {
                background-position: center 20px;
                background-size: auto;
              }
              background-image: url(/media/export/cms/loyalty/marketing_table_tick.png);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 10px;
            }
          }
        }
      }
    }
  }
  .marketing {
    &__tout__enrolment,
    &__tiers__welcome,
    &__points,
    &__faq {
      @include breakpoint($medium-up) {
        margin-left: 220px;
        border-left: 1px solid $color-light-gray;
      }
    }
    &__tiers__welcome {
      @include breakpoint($medium-up) {
        padding-top: 20px;
      }
      .loyalty__benefits__table {
        .benefits__title {
          @include breakpoint($medium-up) {
            margin-top: 0;
          }
        }
      }
    }
    &__enrollment {
      padding: 30px 0;
      margin: 0 auto;
      max-width: $largest-up;
      @include breakpoint($medium-up) {
        margin: 0 30px;
        border-bottom: 1px solid $color-light-gray;
      }
      &__container {
        @include breakpoint($medium-up) {
          padding: 1% 19%;
        }
        padding: 0 16px;
        text-align: center;
        .messages__container__loyalty {
          padding-bottom: 20px;
        }
        .enrolment__optin {
          @include breakpoint($medium-up) {
            padding: 0;
          }
          padding: 0 20px;
        }
        .terms__conditions {
          &__content {
            @include breakpoint($medium-up) {
              font-size: 16px;
            }
            font-size: 18px;
            letter-spacing: 1px;
            text-align: left;
            color: $color-drak-black;
            a {
              text-decoration: underline;
            }
          }
        }
      }
      &__header {
        @include breakpoint($medium-up) {
          font-size: 16px;
          padding: 0;
        }
        margin: 0 0 20px;
        text-align: center;
        font-size: 4.2vw;
        padding: 0 5%;
      }
      &__button__container {
        display: inline-block;
        &.authenticated__container {
          margin-top: 30px;
        }
      }
      &__birthday {
        &__title {
          font-size: 15px;
          letter-spacing: 1px;
          margin: 20px 0 15px;
        }
      }
      &__birth__month,
      &__birth__day {
        @include breakpoint($medium-up) {
          width: 29%;
        }
        @include breakpoint($medium-portrait-only) {
          width: 46%;
        }
        @include breakpoint($medium-landscape-only) {
          width: 40%;
        }
        height: 35px;
        line-height: 1.7;
        width: 45%;
        display: inline-block;
        text-transform: none;
        font-size: 16px;
        min-width: auto;
        select {
          height: 36px;
          line-height: 1;
          text-transform: capitalize;
          font-size: 16px;
          &.error {
            border-color: $color-red;
            color: $color-red;
          }
        }
      }
      &__birth__month {
        margin-right: 3%;
      }
      &__title {
        @include breakpoint($medium-up) {
          font-size: 28px;
        }
        margin: 0;
        font-family: $font--futura-demi;
        font-size: 20px;
        font-weight: normal;
        letter-spacing: 1px;
        padding-bottom: 8px;
        text-align: center;
      }
      &__signin,
      &__learn-more {
        background-color: $color-white;
        color: $color-drak-black;
      }
      &__join,
      &__signin,
      &__learn-more {
        @include breakpoint($medium-up) {
          padding: 5px 30px;
          letter-spacing: normal;
          font-size: 16px;
        }
        padding: 7px 20px;
        border: 1px solid $color-drak-black;
        letter-spacing: 1px;
        font-size: 18px;
        min-width: 135px;
        border-radius: 0;
        display: inline-block;
        text-transform: uppercase;
        &:hover {
          text-decoration: none;
        }
        &.see__rewards__btn {
          width: auto;
        }
      }
      &__join {
        @include primary-hover;
        color: $color-white;
        background-color: $color-black;
        border: none;
        margin-right: 15px;
        &.btn--disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
      .loyalty__points {
        &__section {
          .rewards-btn {
            display: inline-block;
            height: 30px;
            line-height: 2;
            padding: 0 15px;
            font-size: 15px;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    &__tout__enrolment {
      &.marketing__tout__enrolment {
        &__anonymous,
        &__loyalty {
          @include breakpoint($medium-up) {
            height: 170px;
          }
        }
        &__authenticated {
          .marketing__enrollment {
            &__button__container {
              margin-top: 30px;
              display: block;
            }
          }
        }
        &__loyalty {
          .marketing__enrollment {
            padding: 20px 0 40px;
          }
        }
      }
    }
    &__navigation {
      &__list {
        @include breakpoint($medium-up) {
          display: block;
          width: 220px;
          float: left;
        }
        display: none;
        .sidebar-menu {
          &__item {
            padding-left: 30px;
            &.disabled_nav_link {
              color: $color-gray;
            }
          }
        }
      }
    }
    &__content {
      margin: 0 auto;
      max-width: $largest-up;
      .account-utilities {
        padding-top: 20px;
        @include breakpoint($medium-portrait-only) {
          display: block;
        }
      }
      &__tier {
        @include breakpoint($medium-up) {
          display: inline-block;
          width: 25.5%;
          margin: 0 40px;
        }
        display: block;
        width: 100%;
        padding-top: 20px;
        border-top: 10px solid $color-green;
        &__header,
        &__points {
          @include reset-styles;
          text-transform: uppercase;
          color: $color-green;
        }
        &__header {
          font-size: 24px;
        }
        &__list {
          margin: 20px 0 0 20px;
          .highlight {
            font-family: $font--futura-medium;
          }
          .list-item {
            list-style-type: disc;
            line-height: 1.75;
            font-size: 16px;
            &__last {
              visibility: hidden;
            }
          }
        }
        &__offers {
          @include breakpoint($medium-up) {
            padding-bottom: 0;
          }
          padding-bottom: 20px;
          p {
            margin: 0;
          }
          &__header {
            @include reset-styles;
            text-transform: uppercase;
            color: $color-green;
            padding-top: 10px;
          }
          &__image {
            min-height: 165px;
            position: relative;
            &-level {
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
    &__points {
      @include breakpoint($medium-up) {
        margin-left: 220px;
      }
      margin-left: 0;
      padding-top: 70px;
      max-width: $largest-up;
      text-align: center;
      &__header {
        @include breakpoint($medium-up) {
          font-size: 26px;
          font-weight: normal;
          letter-spacing: -0.02em;
        }
        font-size: 20px;
        font-family: $font--futura-medium;
        text-transform: uppercase;
      }
      &__subheader {
        @include breakpoint($medium-up) {
          font-family: $font--futura-demi;
          text-transform: uppercase;
          font-size: 16px;
        }
        @include reset-styles;
        font-family: $font--futura-book;
        text-transform: none;
      }
      &__list {
        @include breakpoint($medium-up) {
          border-right: 1px solid $color-cr18-black;
          display: inline-block;
          padding: 0 40px;
        }
        display: block;
        border-right: none;
        padding: 0;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          border-right: none;
        }
        &__header {
          @include breakpoint($medium-up) {
            font-size: 27px;
            font-weight: normal;
            letter-spacing: 0;
          }
          @include reset-styles;
          text-transform: uppercase;
          font-size: 18px;
          font-family: $font--futura-medium;
          letter-spacing: 2px;
        }
        &__subheader {
          @include breakpoint($medium-up) {
            letter-spacing: 0;
            padding-bottom: 0;
          }
          margin: 0;
          letter-spacing: 1px;
          padding-bottom: 20px;
          &__number {
            vertical-align: super;
            font-size: 10px;
          }
        }
      }
      &__content {
        margin: 30px 0;
      }
      &__disclaimer {
        color: $color-mid-gray;
        font-size: 14px;
        &__list {
          &__item {
            @include breakpoint($medium-up) {
              padding-right: 10px;
              letter-spacing: 0;
              font-size: 16px;
            }
            @include reset-styles;
            display: inline-block;
            &__number {
              vertical-align: super;
              font-size: 10px;
            }
          }
        }
        &__terms {
          font-size: 13px;
          letter-spacing: 0;
          &__link {
            @include secondary-hover;
            text-decoration: underline;
            color: $color-black;
          }
        }
      }
    }
    &__welcome__header {
      position: relative;
      .marketing__banner {
        text-align: center;
        color: $color-black;
        text-transform: uppercase;
        position: relative;
        img {
          @include breakpoint($medium-portrait-only) {
            height: 300px;
          }
        }
        &__section {
          position: absolute;
          top: 21%;
          color: $color-white;
          @include breakpoint($medium-up) {
            left: 0;
            width: 100%;
            top: 30%;
          }
        }
        &__header {
          @include breakpoint($medium-up) {
            font-size: 51px;
          }
          font-size: 32px;
          margin-bottom: 15px;
          line-height: 1;
          letter-spacing: 2.5px;
          color: $color-white;
        }
        &__subheader {
          font-size: 16px;
          color: $color-white;
        }
        &__text {
          @include breakpoint($medium-up) {
            text-transform: uppercase;
          }
          letter-spacing: 1px;
          text-transform: none;
        }
      }
    }
    &__faq {
      @include breakpoint($medium-up) {
        padding-top: 25px;
        margin-left: 220px;
      }
      margin-left: 0;
      padding: 0 3% 5%;
      max-width: $largest-up;
      h3,
      p {
        @include reset-styles;
        font-size: 16px;
      }
      h3 {
        font-family: $font--futura-demi;
        font-weight: normal;
        margin: 0;
      }
      &__header {
        @include breakpoint($medium-up) {
          margin-bottom: 0;
          padding-bottom: 15px;
        }
        font-weight: normal;
        padding: 20px;
        font-size: 28px;
        text-align: center;
      }
      .expando-block {
        border-bottom: 1px solid $color-black;
        margin-bottom: 20px;
        &__content {
          font-size: 13px;
          line-height: 1.5;
          height: auto;
          margin-bottom: 1em;
          h2 {
            font-family: $font--futura-demi;
            font-weight: normal;
          }
          p,
          li {
            font-size: 16px;
            letter-spacing: 1px;
          }
        }
        &.expando-block--expanded {
          .expando-block__content {
            height: auto;
          }
        }
      }
      .accordion {
        &:last-child {
          border-bottom: 1px solid $color-black;
        }
        .collapsible__title {
          font-size: 28px;
          line-height: 1.24;
          padding: 15px 0;
          border-bottom: none;
          text-align: left;
          text-transform: none;
          .accordion__content {
            width: 88%;
            display: inline-block;
            font-size: 28px;
          }
          .collapsible__icon {
            @include breakpoint($medium-up) {
              margin-top: 8px;
            }
            float: right;
            font-size: 24px;
            &:before {
              content: '';
            }
            &.icon {
              &--plus,
              &--minus {
                @include breakpoint($medium-up) {
                  margin: 12px 10px 0 0;
                }
                background-size: 20px;
                background-repeat: no-repeat;
                width: 22px;
                height: 21px;
                margin: 0;
              }
              &--plus {
                background-image: url(/media/export/cms/loyalty/or_mkt_plus.png);
              }
              &--minus {
                background-image: url(/media/export/cms/loyalty/or_mkt_minus.png);
              }
            }
          }
        }
      }
    }
  }
  .page-header__utils {
    .menu__list {
      .utility-nav {
        &__item {
          a {
            @include breakpoint($small-down) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .about__marketing__page {
    @include breakpoint($small-down) {
      display: inline-block;
      border-bottom: 1px solid $color-black;
      width: 100%;
    }
    display: none;
    &__title {
      padding: 20px 0;
      border-left: 1px solid $color-light-gray;
      border-bottom: none;
      float: left;
      display: inline-block;
      width: 80%;
      font-size: 20px;
      font-family: $font--futura-demi;
      font-weight: normal;
      letter-spacing: 0;
      text-align: center;
    }
    .nav__section {
      @include breakpoint($medium-up) {
        display: none;
        float: none;
      }
      padding: 13px 0;
      float: left;
      display: inline-block;
      width: 13%;
      &__link {
        display: block;
      }
      &__back-btn {
        background-color: transparent;
        background-image: url(/media/export/cms/global/carousel.arrows.png);
        background-repeat: no-repeat;
        height: 35px;
        -webkit-transition: none;
        -moz-transition: none;
        transition: none;
        width: 33px;
        background-position-x: 14px;
      }
    }
  }
}
