@if $loyalty-redesign == true {
  .loyalty-offer-code-panel {
    border-top: 1px solid $color-light-gray;
    padding-bottom: 0;
    @include breakpoint($medium-up) {
      border-top: none;
      .expando-block__icon {
        display: none;
      }
    }
    &__title {
      .header-text {
        display: block;
        font-size: 24px;
        font-family: $font--futura-book;
        font-weight: normal;
        margin-bottom: 15px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        @include breakpoint($medium-up) {
          font-size: 26px;
          font-weight: normal;
          margin-bottom: 15px;
        }
      }
    }
    .header-points {
      font-family: $font--futura-demi;
      font-size: 18px;
      text-transform: none;
      margin-bottom: 15px;
      @include breakpoint($medium-up) {
        font-size: 19px;
      }
    }
    .header-disclaimer {
      font-family: $font--futura-book;
      line-height: 1;
      font-size: 15px;
      letter-spacing: 0;
      color: $color-dark-gray;
      margin-bottom: 15px;
      @include breakpoint($medium-up) {
        font-size: 13px;
      }
    }
    .offer-code-wallet__code__description {
      &-title {
        min-height: 72px;
        font-family: $font--futura-demi;
        font-size: 15px;
        width: 100%;
        text-transform: uppercase;
        margin-top: 10px;
        color: $color-drak-black;
        @include breakpoint($medium-up) {
          min-height: 54px;
          text-align: left;
        }
      }
      &-description {
        min-height: 72px;
        font-family: $font--futura-medium;
        font-size: 15px;
        letter-spacing: 0;
        text-transform: capitalize;
        margin-top: 10px;
      }
      &-points {
        min-height: 48px;
        font-family: $font--futura-demi;
        font-size: 15px;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        margin-top: 10px;
        color: $color-drak-black;
        @include breakpoint($medium-up) {
          min-height: 36px;
          text-align: left;
        }
      }
    }
    .product__sku-details {
      text-align: center;
      padding: 0 5px;
      @include breakpoint($medium-up) {
        min-height: 22px;
        text-align: left;
        padding: 0 10px;
      }
    }
    .offer-code-content__wallet {
      padding: 0 20px;
      @include breakpoint($medium-up) {
        padding: 0 30px;
      }
    }
    .offer-code-wallet__button {
      &--remove {
        background-color: $color-white;
        color: $color-drak-black;
        border: 1px solid $color-drak-black;
      }
      &--apply,
      &--remove {
        border-radius: 0;
        width: auto;
        letter-spacing: 1px;
        line-height: 2;
        display: block;
        height: 32px;
        font-size: 16px;
        padding: 0 20px;
        @include breakpoint($medium-up) {
          display: inline-block;
          font-size: 13px;
          letter-spacing: 0.5px;
          line-height: 2.3;
        }
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: $color-drak-black;
          color: $color-white;
        }
        &.button--disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: $color-drak-black;
          box-shadow: none;
        }
      }
    }
    .product__actions {
      @include breakpoint($medium-up) {
        display: block;
      }
      margin-top: 15px;
      display: inline-block;
    }
    .slick-prev,
    .slick-next {
      top: 40%;
      @include breakpoint($medium-up) {
        top: 41%;
      }
      &:before {
        @include breakpoint($medium-up) {
          background-size: auto;
        }
        background-size: 30px;
        background-image: url(/media/images/global/arrow_down_black_old.png);
        content: '';
        display: block;
        background-repeat: no-repeat;
        width: 36px;
        height: 36px;
      }
    }
    .slick-next {
      top: 38%;
      right: 0;
      @include breakpoint($medium-up) {
        top: 41%;
      }
      &:before {
        transform: rotate(270deg);
        right: -15px;
        @include breakpoint($medium-up) {
          right: -5px;
        }
      }
    }
    .slick-prev {
      &:before {
        transform: rotate(90deg);
        left: -15px;
      }
    }
    &__disclaimer {
      &__text {
        font-family: $font--futura-book;
        line-height: 1;
        font-size: 15px;
        letter-spacing: 0;
        color: $color-dark-gray;
        margin-bottom: 15px;
        @include breakpoint($medium-up) {
          font-size: 13px;
        }
      }
    }
  }
  .checkout {
    .loyalty__points__description {
      padding: 1em 0;
      border-top: 1px solid $color-light-gray;
      font-family: $font--futura-medium;
      float: left;
      width: 100%;
      @include breakpoint($medium-up) {
        margin-top: 1em;
        padding-bottom: 0;
      }
      .loyalty-member__data,
      .non-member__data {
        padding: 0 1em;
      }
      .disclaimer {
        margin-top: 15px;
        font-family: $font--futura-book;
        line-height: 1;
        color: $color-dark-gray;
      }
      .highlighted__text {
        color: $color-green;
      }
      .non-member__data {
        font-family: $font--futura-medium;
        padding-bottom: 1em;
        .highlighted__text {
          font-family: $font--futura-demi;
        }
      }
    }
  }
  .loyalty-benefit-code-panel {
    padding-bottom: 0;
    .loyalty-benefit-code-panel {
      &__title {
        margin-bottom: 1em;
        .header-text {
          display: block;
          font-size: 24px;
          font-family: $font--futura-book;
          font-weight: normal;
          margin-bottom: 15px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          @include breakpoint($medium-up) {
            font-size: 26px;
            font-weight: normal;
            margin-bottom: 15px;
          }
        }
      }
    }
    .loyalty-offer-panel__content {
      padding: 18px 24px;
    }
    .benefit-code-wallet__code__description {
      &-title {
        min-height: 72px;
        font-family: $font--futura-demi;
        font-size: 15px;
        width: 100%;
        text-transform: uppercase;
        margin-top: 10px;
        color: $color-drak-black;
        @include breakpoint($medium-up) {
          min-height: 54px;
        }
      }
      &-description {
        min-height: 42px;
        font-family: $font--futura-medium;
        font-size: 15px;
        letter-spacing: 0;
        text-transform: capitalize;
        margin-top: 10px;
        @include breakpoint($medium-up) {
          min-height: 54px;
        }
      }
      &-points {
        min-height: 48px;
        font-family: $font--futura-demi;
        font-size: 15px;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        margin-top: 10px;
        color: $color-drak-black;
        @include breakpoint($medium-up) {
          min-height: 36px;
          text-align: left;
        }
      }
    }
    .benefit-code-wallet__code {
      &-item__image {
        width: 144px;
      }
      &-count {
        text-align: center;
      }
    }
    .product__sku-details {
      text-align: center;
      padding: 0 5px;
    }
    .slick-prev,
    .slick-next {
      top: 40%;
      @include breakpoint($medium-up) {
        top: 41%;
      }
      &::before {
        @include breakpoint($medium-up) {
          background-size: auto;
        }
        background-size: 30px;
        background-image: url('/media/images/global/arrow_down_black_old.png');
        content: '';
        display: block;
        background-repeat: no-repeat;
        width: 36px;
        height: 36px;
      }
    }
    .slick-next {
      top: 38%;
      right: 0;
      @include breakpoint($medium-up) {
        top: 41%;
      }
      &::before {
        transform: rotate(270deg);
        right: -15px;
        @include breakpoint($medium-up) {
          right: -5px;
        }
      }
    }
    .product__actions {
      margin-top: 10px;
    }
    .slick-prev {
      &::before {
        transform: rotate(90deg);
        left: -15px;
      }
    }
    .benefit-code-wallet__button {
      &--apply,
      &--remove {
        border-radius: 0;
        width: auto;
        letter-spacing: 1px;
        line-height: 2;
        display: block;
        height: 32px;
        font-size: 16px;
        padding: 0 20px;
        margin: 0 auto;
        @include breakpoint($medium-up) {
          display: inline-block;
          font-size: 13px;
          letter-spacing: 0.5px;
          line-height: 2.3;
        }
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: $color-drak-black;
        }
        &.button--disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: $color-drak-black;
          box-shadow: none;
        }
      }
    }
  }
  .opc__cart {
    .checkout__sidebar {
      &.sidebar__content__wrapper {
        display: block;
      }
      .loyalty-benefit-code-panel {
        @include breakpoint($medium-up) {
          padding-top: 1em;
        }
        border-top: 1px solid $color-light-gray;
        display: block;
        padding-bottom: 0;
        .loyalty-benefit-code-panel {
          &__title {
            margin-bottom: 1em;
            .header-text {
              display: block;
              font-size: 24px;
              font-family: $font--futura-book;
              font-weight: normal;
              margin-bottom: 15px;
              text-transform: uppercase;
              letter-spacing: 0.5px;
              @include breakpoint($medium-up) {
                font-size: 26px;
                font-weight: normal;
                margin-bottom: 15px;
              }
            }
          }
        }
        .loyalty-offer-panel__content {
          padding: 18px 24px;
        }
        .benefit-code-wallet__code__description {
          &-title {
            min-height: 72px;
            font-family: $font--futura-demi;
            font-size: 15px;
            width: 100%;
            text-transform: uppercase;
            margin-top: 10px;
            color: $color-drak-black;
            @include breakpoint($medium-up) {
              min-height: 54px;
            }
          }
          &-description {
            min-height: 42px;
            font-family: $font--futura-medium;
            font-size: 15px;
            letter-spacing: 0;
            text-transform: capitalize;
            margin-top: 10px;
            @include breakpoint($medium-up) {
              min-height: 54px;
            }
          }
          &-points {
            min-height: 48px;
            font-family: $font--futura-demi;
            font-size: 15px;
            text-align: center;
            width: 100%;
            text-transform: uppercase;
            margin-top: 10px;
            color: $color-drak-black;
            @include breakpoint($medium-up) {
              min-height: 36px;
              text-align: left;
            }
          }
        }
        .benefit-code-wallet__code {
          &-item__image {
            width: 144px;
          }
          &-count {
            text-align: center;
          }
        }
        .product__sku-details {
          text-align: center;
          padding: 0 5px;
        }
        .slick-prev,
        .slick-next {
          top: 40%;
          @include breakpoint($medium-up) {
            top: 41%;
          }
          &::before {
            @include breakpoint($medium-up) {
              background-size: auto;
            }
            background-size: 30px;
            background-image: url('/media/images/global/arrow_down_black_old.png');
            content: '';
            display: block;
            background-repeat: no-repeat;
            width: 36px;
            height: 36px;
          }
        }
        .slick-next {
          top: 38%;
          right: 0;
          @include breakpoint($medium-up) {
            top: 41%;
          }
          &::before {
            transform: rotate(270deg);
            right: -15px;
            @include breakpoint($medium-up) {
              right: -5px;
            }
          }
        }
        .product__actions {
          margin-top: 10px;
        }
        .slick-prev {
          &::before {
            transform: rotate(90deg);
            left: -15px;
          }
        }
        .benefit-code-wallet__button {
          &--apply,
          &--remove {
            border-radius: 0;
            width: auto;
            letter-spacing: 1px;
            line-height: 2;
            display: block;
            height: 32px;
            font-size: 16px;
            padding: 0 20px;
            margin: 0 auto;
            @include breakpoint($medium-up) {
              display: inline-block;
              font-size: 13px;
              letter-spacing: 0.5px;
              line-height: 2.3;
            }
            &:hover,
            &:focus {
              text-decoration: none;
              background-color: $color-drak-black;
            }
            &.button--disabled {
              opacity: 0.5;
              cursor: not-allowed;
              background-color: $color-drak-black;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
