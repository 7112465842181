@if $loyalty-redesign == true {
  .loyalty__panel__points__history {
    .loyalty__panel__transactions__content {
      .loyalty__panel__transactions__list {
        &__column {
          &-activity {
            @include breakpoint($medium-up) {
              width: 70%;
              padding-left: 14px;
            }
            display: table-cell;
            width: 60%;
            font-size: 16px;
            &.activity-details {
              @include breakpoint($medium-up) {
                text-align: left;
              }
              font-size: 14px;
            }
            &--level {
              &.points__activity {
                @include breakpoint($medium-up) {
                  padding-left: 15px;
                }
                padding: 10px 0;
                font-weight: normal;
                text-transform: none;
                font-size: 16px;
              }
            }
          }
          &-date {
            @include breakpoint($medium-up) {
              padding-left: 0;
              width: 12%;
              text-align: left;
            }
            width: 28%;
          }
          &-points {
            @include breakpoint($medium-up) {
              width: 8%;
              text-align: center;
            }
          }
          &-earned {
            &.expando-menu-trigger {
              @include breakpoint($medium-up) {
                background-image: none;
              }
              @include breakpoint($small-down) {
                width: 12%;
              }
              text-align: center;
              background-image: url(/media/export/cms/loyalty/or_loyalty_plus.png);
              background-size: 23px;
              background-repeat: no-repeat;
              background-position: center;
              &:after {
                font-size: 18px;
                position: relative;
                top: auto;
                content: '';
              }
            }
          }
        }
        .points__history__wrapper {
          @include breakpoint($medium-up) {
            border-bottom: 1px solid $color-light-gray;
            margin-bottom: 40px;
          }
          table {
            tr {
              @include breakpoint($small-down) {
                width: 100%;
              }
            }
            td {
              @include breakpoint($small-down) {
                padding: 0.9em;
                line-height: 1;
              }
              &.loyalty__panel__transactions__list {
                &__column-date {
                  @include breakpoint($small-down) {
                    padding-top: 15px;
                  }
                }
              }
            }
          }
        }
        .loyalty__panel__transactions {
          &__rewards-history {
            min-height: 0;
          }
          &__title {
            &.points__history__title {
              @include breakpoint($medium-up) {
                font-size: 28px;
              }
              font-family: $font--futura-demi;
              font-size: 20px;
              text-align: center;
              border: none;
              letter-spacing: 0.5px;
            }
          }
          &__table {
            @include breakpoint($medium-up) {
              border-bottom: none;
              margin: 60px 0 31px;
              width: 100%;
            }
            margin: 28px 0 20px;
            border-top: 1px solid $color-light-gray;
            border-bottom: 1px solid $color-light-gray;
            .loyalty__panel__transactions__list {
              &__column {
                &-earned {
                  @include breakpoint($medium-up) {
                    text-align: center;
                  }
                }
                &-activity__points {
                  @include breakpoint($medium-up) {
                    display: none;
                  }
                  display: inline;
                }
              }
            }
            thead {
              background: none;
              th {
                @include breakpoint($medium-up) {
                  font-size: 13px;
                  padding: 40px 0 8px;
                  font-weight: normal;
                }
                letter-spacing: 1px;
                font-family: $font--futura-medium;
                text-transform: uppercase;
              }
            }
            tbody {
              tr {
                @include breakpoint($medium-up) {
                  border: none;
                }
                border-top: 1px solid $color-light-gray;
                border-bottom: 1px solid $color-light-gray;
                td {
                  @include breakpoint($medium-up) {
                    padding: 6px 0;
                    letter-spacing: normal;
                    font-size: 14px;
                  }
                  letter-spacing: 1px;
                }
                &.view__more__details {
                  border-top: none;
                  .rewards__benefits {
                    padding-top: 0;
                    div {
                      display: block;
                    }
                    .points__text,
                    .balance__text {
                      font-family: $font--futura-medium;
                    }
                    .points__value {
                      color: $color-green;
                      font-family: $font--futura-medium;
                    }
                  }
                }
                &.expando-menu__parent--expanded {
                  border-bottom: none;
                  td {
                    @include breakpoint($small-down) {
                      padding-bottom: 3px;
                    }
                  }
                  .loyalty__panel__transactions {
                    &__list__column {
                      &-activity {
                        @include breakpoint($small-down) {
                          visibility: hidden;
                        }
                      }
                    }
                  }
                  .expando-menu-trigger {
                    background-image: url(/media/export/cms/loyalty/or_loyalty_minus.png);
                  }
                }
                &:last-child {
                  td {
                    @include breakpoint($medium-up) {
                      padding-bottom: 6px;
                    }
                    padding-bottom: 10px;
                  }
                }
              }
            }
          }
          &__paginate {
            @include breakpoint($medium-up) {
              padding-top: 20px;
              margin-bottom: 0;
            }
            margin-bottom: 35px;
            text-align: center;
            .show__more__btn {
              @include breakpoint($medium-up) {
                font-size: 16px;
                letter-spacing: normal;
              }
              text-decoration: none;
              display: block;
              font-size: 18px;
              letter-spacing: 1px;
              &__image {
                @include breakpoint($medium-up) {
                  width: 11px;
                  bottom: 15px;
                }
                background-image: url(/media/export/cms/global/carousel.arrows.png);
                background-repeat: no-repeat;
                height: 55px;
                text-indent: -9999px;
                -webkit-transition: none;
                -moz-transition: none;
                transition: none;
                width: 14px;
                opacity: 1;
                text-align: center;
                display: inline-block;
                transform: rotate(270deg);
                -webkit-transform: rotate(270deg);
                -moz-transform: rotate(270deg);
                -ms-transform: rotate(270deg);
                -o-transform: rotate(270deg);
                position: relative;
                bottom: 12px;
                left: 8px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
