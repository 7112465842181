.loyalty-only--block {
  display: block;
}

.loyalty-only--inline {
  display: inline;
}

.checkout__content .review-panel__loyalty-points {
  display: none;
}

.loyalty_wrapper {
  @include wrapper;
  padding: 0;
  position: relative;
}

.loyalty-nav__tab {
  .loyalty-nav__link {
    font-family: 'Times New Roman';
    font-style: italic;
  }
  &.loyalty-nav__tab--active {
    .loyalty-nav__link {
      font-style: initial;
    }
  }
}

#loyalty__navigation {
  .account-utilities {
    padding: 20px;
    width: 200px;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
}

.sidebar-menu__item_loyalty_curr_pts,
.signin-block__item_loyalty_curr_pts {
  font-size: 18px;
  font-style: italic;
}

.sidebar-menu__item_loyalty_points,
.signin-block__item_loyalty_points {
  font-size: 36px;
  font-family: $font--cta;
  font-style: italic;
}

.sidebar-menu__item_loyalty_points {
  @include breakpoint($medium-up) {
    width: 165px;
    word-wrap: break-word;
  }
}

.not_lyl_enrolled .lyl_enrolled_show,
.tier_1 .not_lyl_enrolled_show,
.tier_2 .not_lyl_enrolled_show,
.tier_3 .not_lyl_enrolled_show {
  display: none;
}

.signin-block__authed__status {
  padding: 16px 20px 14px 20px;
  border-top: solid 1px $color-black;
  a {
    text-decoration: none;
  }
}

.signin-block__item_loyalty_reward {
  font-size: 16px;
  color: $color-black;
  margin: 0 0 8px 0;
  &.disabled_nav_link {
    color: $color-gray;
  }
}

.loyalty_content {
  width: 100%;
  @include breakpoint($landscape-up) {
    padding-left: 200px;
  }
}

.loyalty_header_nav {
  display: none;
  height: 290px;
  width: 100%;
  border-bottom: 1px solid $color-black;
  text-align: center;
  position: relative;
  @include breakpoint($medium-up) {
    display: block;
  }
  &.content-header__inner {
    background-image: url('/media/images/loyalty/new_lyl_header_nav_bg.jpg');
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
    .content-header__text-inner {
      padding: 20px 0;
    }
    .content-header__text-wrapper {
      top: 43%;
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &_title {
    font-size: 60px;
    font-family: $font--heading;
    padding: 60px 0 15px;
  }
  &_nav {
    background-color: $color-dark-gray;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 95%;
    padding: 2px;
    text-align: center;
    margin: 0 auto;
    &_options {
    }
    &_blurb {
      font-family: $font--subheading;
      font-size: 24px;
    }
  }
  .loyalty-nav {
    &__tabs {
      left: 0;
      right: 0;
      background-color: transparent;
      height: auto;
      padding-bottom: 0;
      max-width: 1000px;
    }
    &__links {
      height: 42px;
      line-height: 47px;
      font-family: 'Times New Roman';
      font-style: none;
    }
  }
}

.loyalty_mobile_nav {
  background: $color-dark-gray;
  color: $color-white;
  display: block;
  height: 53px;
  line-height: 53px;
  margin: 0 0 1px 0;
  padding: 0 15px;
  width: 100%;
  @include breakpoint($medium-up) {
    display: none;
  }
  i {
    @include icon(plus);
    float: right;
  }
  &-active {
    background: $color-light-gray;
    color: $color-black;
    font-style: italic;
    margin: 0 0 20px 0;
    i {
      @include icon(minus);
    }
  }
}

#loyalty__content {
  width: 100%;
  @include breakpoint($medium-up) {
    float: right;
  }
}

#loyalty__panel__also_like {
  .loyalty_also_like {
    width: 100%;
    text-align: center;
    clear: right;
    font-family: $font--heading;
    font-size: 16px;
    height: 50px;
    line-height: 65px;
  }
  .product-grid__content {
    width: 100%;
    border-color: $color-light-gray; /* #d1d1d1 */
    border-top: solid 1px $color-light-gray;
  }
  .product-grid__item {
    border-color: $color-light-gray; /* #d1d1d1 */
  }
  .product-brief__rating__img {
    padding-top: 10px;
  }
  .product-design_regimen {
    padding-top: 300px;
    .design_regimen {
      font-family: $font--subheading;
      font-size: 24px;
      color: $color-light-gray;
    }
  }
  .product-brief.product-brief-v2 {
    min-height: 500px;
  }
}

#loyalty__panel__redeem {
  .product-brief.product-brief-v2 {
    min-height: 400px;
  }
}

.loyalty__panel__offers__offer {
  border-bottom: solid 1px $color-light-gray;
  color: $color-black;
  font-family: $font--subheading;
  position: relative;
  width: 100%;
  text-align: center;
  .loyalty__panel__redeem & {
    @include breakpoint($medium-up) {
      text-align: center;
      margin: 20px 0 0;
      min-height: 230px;
    }
  }
  &.offer_1 {
    @include breakpoint($medium-up) {
      .loyalty_offer_left {
        margin-right: 0;
      }
      .loyalty_offer_right {
        border-left: 1px solid $color-light-gray;
        float: left;
        margin: 25px 0 25px -1px;
        padding: 0 0 0 26px;
        width: 34%;
        .redeem_tier1_items {
          display: inline-block;
        }
      }
    }
    @include breakpoint($large-up) {
      .loyalty_offer_image_blk.loyalty_offer__product-available {
        margin-top: 5%;
      }
      .loyalty_offer_left.loyalty_offer__product-available {
        margin-top: 10%;
      }
    }
  }
  .loyalty_offer {
    &_image_blk {
      position: relative;
      @include breakpoint($medium-up) {
        position: absolute;
        top: 0;
        left: 0;
      }
      .hero-large__text {
        margin: 10px 0 0;
        line-height: 1em;
        font-size: 26px;
        @include breakpoint($small-down) {
          clear: both;
        }
      }
    }
    &_desc {
      margin: 20px 20px 20px 0;
    }
    &_image {
      .loyalty__panel__redeem & {
        float: left;
        margin: 0 2% 10px 2%;
        display: none;
        @include breakpoint($medium-up) {
          float: none;
          margin: 0;
          max-height: none;
          z-index: -1;
          display: block;
        }
        &.mobile_thumb {
          margin-top: 0 !important;
          display: block;
          @include breakpoint($medium-up) {
            display: none;
          }
        }
      }
    }
    .category_offer_thumbs {
      @include breakpoint($medium-up) {
        float: none;
        padding: 0;
        width: auto;
        max-width: 690px;
        margin: 0 auto;
      }
      .category_offer_container_1,
      .category_offer_container_2,
      .category_offer_container_3 {
        float: none;
        @include breakpoint($medium-up) {
          float: left;
        }
      }
      .category_offer_container_1 {
        margin-top: 25px;
        @include breakpoint($medium-up) {
          margin-top: 0;
        }
      }
      .category_offer_container_2 {
        display: inline-block;
      }
      img {
        height: 200px;
        @include breakpoint($medium-up) {
          &.thumb1 {
            width: 73px;
          }
          &.thumb2 {
            width: 68px;
          }
          &.thumb3 {
            width: 65px;
          }
          &.thumb4 {
            width: 66px;
          }
          &.thumb5 {
            width: 46px;
          }
          &.thumb6 {
            width: 60px;
          }
          &.thumb7 {
            width: 73px;
          }
          &.thumb8 {
            width: 84px;
          }
          &.thumb9 {
            width: 50px;
          }
          &.thumb10 {
            width: 72px;
          }
        }
      }
    }
    &_left {
      border-bottom: 1px solid $color-light-gray;
      margin: 25px 2% 0 2%;
      padding-bottom: 25px;
      @include breakpoint($medium-up) {
        border: none;
        border-right: 1px solid $color-light-gray;
        float: left;
        margin-bottom: 25px;
        padding: 0;
        width: 64%;
      }
      @include breakpoint($small-down) {
        height: auto !important;
        .offer_content {
          margin-top: 0 !important;
        }
      }
      i {
        @include icon(plus);
        cursor: pointer;
        float: right;
        line-height: 43px;
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      &.open {
        i {
          @include icon(minus);
          @include breakpoint($medium-up) {
            display: none;
          }
        }
      }
      .loyalty__panel__redeem & {
        @include breakpoint($medium-up) {
          padding-left: 230px;
        }
      }
    }
    &_right {
      display: none;
      padding: 25px 4%;
      @include breakpoint($medium-up) {
        display: block;
        float: right;
        padding: 25px 5px;
        text-align: center;
        width: 32%;
      }
      & {
        text-align: center;
      }
      &.open {
        display: block;
      }
      &-earned {
        float: left;
        text-align: center;
        width: 49%;
        @include breakpoint($medium-up) {
          float: none;
          width: auto;
        }
      }
      &-availability {
        float: right;
        text-align: center;
        width: 49%;
        @include breakpoint($medium-up) {
          float: none;
          width: auto;
        }
      }
    }
    &_type,
    &_availability,
    &_not_available {
      color: $color-green;
      font-size: 26px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    &_title {
      font-size: 36px;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        font-size: 72px;
      }
      .loyalty__panel__redeem & {
        font-size: 36px;
      }
    }
    &_value {
      display: none;
    }
    &_line {
      display: none;
      width: 40px;
      height: 1px;
      background-color: $color-black;
      margin: 5px auto 20px;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    &_blurb {
      font-family: $font--text;
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 18px;
      }
      .loyalty__panel__redeem & {
        clear: both;
        @include breakpoint($medium-up) {
          clear: none;
          font-size: 18px;
          padding-right: 25px;
        }
      }
      a {
        font-family: $font--cta;
        font-size: 16px;
        border-bottom: solid 1px $color-black;
        margin-left: 50px;
        text-decoration: none;
      }
    }
  }
  .button {
    margin: 10px 0 0 0;
    padding: 2px 90px 0;
    width: 100%;
    @include breakpoint($medium-up) {
      width: auto;
    }
    @include breakpoint($medium-down) {
      padding: 2px 60px 0;
    }
  }
  .loyalty_offer_form .button {
    margin: 0;
    padding: 1px 30px;
    &.disabled {
      background-color: $color-light-gray;
      box-shadow: 0 0 0 1px $color-light-gray;
      cursor: default;
    }
  }
  .loyalty_offer_expires {
    font-family: $font--text;
    font-size: 14px;
  }
  .loyalty_offer_sku_array_txt {
    font-family: $font--text;
  }
  .loyalty_offer_sku_array_skus_item {
    font-family: $font--text;
    font-size: 12px;
    width: 100px;
    height: 180px;
    margin: 10px 0 0;
    float: left;
    position: relative;
    @include breakpoint($medium-down) {
      width: 70px;
    }
    img {
      position: relative;
      clear: both;
    }
    input {
      bottom: 10px;
      left: 43px;
      @include breakpoint($medium-down) {
        left: 30px;
      }
    }
    .loyalty_offer_sku_array_skus_item_name {
      color: $color-light-green;
      font-size: 9px;
      margin: 0 0 6px;
      font-family: $font--heading;
    }
  }
  &.loyalty_offer_img {
    .loyalty_offer_right {
      @include breakpoint($medium-up) {
        padding: 100px 25px 0;
      }
      text-align: center;
    }
  }
  &.loyalty_offer_form {
    .loyalty_offer_right {
      @include breakpoint($medium-up) {
        padding: 65px 25px 0;
      }
      text-align: center;
    }
    input[type='text'] {
      margin: 10px 0 5px 0;
      width: 100%;
      @include breakpoint($medium-up) {
        margin: 1px 10px 0 0;
        width: 30%;
      }
    }
    .err_msg {
      color: $color-red;
      font-family: $font--text;
      margin-top: 1em;
    }
    .succ_msg {
      color: $color-green;
      font-family: $font--text;
      margin-top: 1em;
    }
  }
}

@include breakpoint($large-up) {
  .loyalty__panel__redeem.loyalty__NA {
    .loyalty__panel__offers__offer.offer_1 {
      .loyalty_offer_image_blk.loyalty_offer__product-available {
        margin-top: 0;
      }
    }
  }
}

#loyalty__page__earn .loyalty__panel__offers__offer {
  padding: 55px 0;
  .loyalty_offer_title {
    text-transform: none;
    font-family: $font--text;
    font-size: 54px;
    line-height: 1em;
    margin: 20px 0;
  }
  &__shopping {
    background: url('/media/images/loyalty/new_lyl_pts_bg-1.jpg') top center no-repeat;
  }
  &__cats {
    background: url('/media/images/loyalty/new_lyl_cat_bg-1.jpg') top center no-repeat;
  }
  &__miniface {
    background: url('/media/images/loyalty/new_lyl_minifacial_bg-2.jpg') 2px center no-repeat;
  }
}

.loyalty_offer_snipp {
  background-color: $color-silver;
}

.loyalty_offer_earned {
  font-size: 24px;
}

.loyalty_offer_points {
  font-size: 36px;
  font-family: $font--cta;
}

.loyalty_offer_points_type {
  font-family: $font--cta;
  font-size: 14px;
  text-decoration: none;
}

.loyalty_offer_availability,
.loyalty_offer_not_available {
  @include breakpoint($medium-up) {
    margin: 30px 0 5px;
  }
}

.redeem_default .loyalty_offer_availability,
.redeem_default .loyalty_offer_not_available {
  margin: 0 0 5px;
}

.loyalty__panel__offers__offer .loyalty_offer_not_available {
  color: $color-dark-gray;
}

.loyalty_offer_note {
  font-family: $font--text;
  font-size: 14px;
}

.loyalty_offer_blurb_more,
.loyalty_offer_blurb_less {
  text-transform: uppercase;
}
/* REDEEM */
.loyalty__panel__redeem {
  .loyalty_boutique_breadcrumb {
    font-family: $font--subheading;
    font-size: 18px;
    color: $color-black;
    padding: 15px 20px;
    border-bottom: solid 1px $color-black;
  }
  .Silver .loyalty_status.tier_1,
  .Gold .loyalty_status.tier_2,
  .Green .loyalty_status.tier_3,
  .tier_1 .loyalty_status.tier_1,
  .tier_2 .loyalty_status.tier_2,
  .tier_3 .loyalty_status.tier_3 {
    h5.loyalty_status_header,
    .at-h5-loyalty-status {
      color: $color-green;
      margin-top: 21px;
      @include breakpoint($medium-up) {
        margin-top: 13px;
      }
    }
  }
  .your_status {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      &.mobile_tier_level {
        display: none;
      }
    }
  }
  .reach_status {
    display: none;
  }
  .loyalty_h3 {
    width: 100%;
    text-align: center;
    clear: right;
    font-family: $font--subheading;
    font-size: 35px;
    font-weight: 400;
    margin: 15px 0;
    line-height: 2.3em;
    i {
      text-transform: none;
      font-size: 20px;
      font-family: $font--text;
      line-height: 1.3em;
      display: block;
    }
  }
  .loyalty_h3.rewards_header {
    border-bottom: 1px solid $color-light-gray;
    font-size: 20px;
    letter-spacing: 0.025em;
    margin: 0;
    padding-bottom: 2px;
    padding-top: 2px;
    @include breakpoint($medium-up) {
      display: none;
      // font-size: 24px;
      // letter-spacing: 0;
      // margin: 15px 0 0;
      // padding-top: 0;
      // padding-bottom: 0;
    }
  }
  .product-brief__rating,
  .product-brief__price-points,
  .product-brief__ctas--desktop {
    display: none;
  }
  .cart_selected .product-brief__cta--shop-now,
  .max_selected .product-brief__cta--shop-now,
  .max_selected .product-brief__cta-add-to-bag .product-add-to-bag,
  .max_selected .product-brief__cta-add-to-bag .product-add-to-bag:hover,
  .redeem_checkout_btn.disabled {
    background-color: $color-light-gray;
    box-shadow: 0 0 0 1px $color-light-gray;
    cursor: default;
  }
  .max_selected .product-brief__cta-add-to-bag .product-add-to-bag a {
    text-decoration: none;
  }
  .max_selected {
    .product-brief__cta-add-to-bag {
      .product-add-to-bag.cart_selected {
        background-color: $color-white;
        color: $color-drak-black;
      }
    }
  }
  .product-brief__cta-add-to-bag.inactive_button {
    .product-add-to-bag {
      background-color: $color-medium-gray;
      cursor: default;
      pointer-events: none;
    }
  }
  .product-brief__benefit {
    padding: 10px 0;
  }
  .carousel-controls {
    .slick-arrow {
      color: $color-dark-gray;
    }
  }
  .remove_redeem_item {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    display: none;
  }
  .cart_selected .remove_redeem_item {
    display: block;
  }
  .loyalty_h3 {
    margin-bottom: 0;
  }
  .loyalty_statuses {
    border-bottom: solid 3px $color-black;
    border-top: solid 1px $color-light-gray;
    padding: 5px 0;
    @include breakpoint($medium-up) {
      border-bottom: solid 1px $color-black;
      text-align: center;
      display: inline-block;
      width: 100%;
    }
    .tier_level {
      @include t10;
      display: block;
      margin: 6px auto 0;
      width: 320px;
      li {
        display: inline-block;
        text-transform: uppercase;
        text-decoration: underline;
        padding: 10px 0;
        vertical-align: top;
        letter-spacing: 0.045em;
      }
      li.dot {
        text-decoration: none;
        font-size: 28px;
        padding: 0;
      }
      li.tier_selected {
        color: $color-green;
      }
      li.tier_1 {
        margin-left: 23px;
        margin-right: 16px;
      }
      li.tier_2 {
        margin-left: 14px;
        margin-right: 16px;
      }
      li.tier_3 {
        margin-left: 13px;
      }
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    .loyalty_status.current_tier {
      h5.loyalty_status_header,
      .at-h5-loyalty-status {
        color: $color-green;
        margin-top: 8px;
      }
    }
    .loyalty_status {
      width: 100%;
      min-height: 207px;
      border-left: solid 1px $color-light-gray;
      position: relative;
      padding: 15px 15px 15px 110px;
      display: none;
      @include breakpoint($medium-up) {
        width: 33%;
        float: left;
        display: block;
        min-height: 180px;
        padding: 15px 15px 15px 130px;
      }
      i {
        @include icon(plus);
        cursor: pointer;
        float: right;
        line-height: 70px;
        display: none;
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      &.open {
        display: block;
        i {
          @include icon(minus);
          @include breakpoint($medium-up) {
            display: none;
          }
        }
      }
      h5,
      .at-h5-loyalty-status {
        color: $color-green;
        font-size: 24px;
        margin: 1em;
        font-family: $font--heading;
        font-weight: 100;
        @include breakpoint($medium-up) {
          text-align: center;
        }
      }
      h5.loyalty_status_header,
      .at-h5-loyalty-status {
        color: $color-black;
        margin-bottom: 0;
        margin-top: 21px;
        margin-left: 43px;
        @include breakpoint($medium-up) {
          font-size: 19px;
          margin-top: 13px;
          margin-left: 1em;
        }
      }
      p {
        margin-top: 0;
        margin-bottom: 0.5em;
        font-family: $font--cta;
        font-size: 14px;
        letter-spacing: 1px;
        margin-left: 46px;
        @include breakpoint($medium-up) {
          text-align: center;
          margin-left: initial;
        }
      }
      &.loyalty_status-silver {
        border-left: none;
        img {
          bottom: 10px;
          @include breakpoint($medium-up) {
            bottom: -17px;
          }
        }
      }
      &.loyalty_status-gold {
        h5.loyalty_status_header,
        .at-h5-loyalty-status {
          margin-left: 51px;
          @include breakpoint($medium-up) {
            margin-left: 1em;
          }
        }
        @include breakpoint($medium-up) {
          width: 33.33%;
        }
        img {
          bottom: 10px;
          @include breakpoint($medium-up) {
            bottom: -5px;
          }
        }
      }
      &.loyalty_status-green {
        h5.loyalty_status_header,
        .at-h5-loyalty-status {
          margin-left: 43px;
          @include breakpoint($medium-up) {
            margin-left: 0;
          }
        }
        img {
          bottom: 10px;
          @include breakpoint($medium-up) {
            bottom: -5px;
          }
        }
      }
      img {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
      ul {
        margin-top: 17px;
        @include breakpoint($medium-up) {
          margin-top: 0;
        }
        li {
          font-size: 14px;
          line-height: 1.5;
          @include breakpoint($medium-up) {
            line-height: inherit;
          }
        }
      }
    }
  }
  .redeem_cart_item {
    /* float: right; */
    margin: 20px 0;
    border-bottom: solid 1px $color-light-gray;
    @include breakpoint($medium-up) {
      margin: 20px;
    }
    .redeem_cart_item_close {
      position: absolute;
      top: -20px;
      right: 10px;
      font-family: $font--subheading;
      font-size: 24px;
    }
    &.redeem_cart_item_last {
      border-color: $color-black;
    }
    position: relative;
    img {
      margin: 0 20px;
      position: absolute;
      top: 3px;
      left: 0;
    }
    .redeem_cart_item_text {
      width: 100%;
      min-height: 100px;
      padding-left: 106px;
      h5 {
        margin: 0;
        font-family: $font--subheading;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: normal;
      }
      p {
        font-family: $font--text;
        font-size: 16px;
        .fa-chevron-up {
          display: none;
        }
      }
    }
    .redeem_cart_item_text_size {
      display: none;
    }
    .redeem_cart_item_text_qty {
      display: none;
    }
  }
  .redeem_checkout_cart_items {
    border-top: solid 1px $color-black;
    padding: 10px 0 0;
    .redeem_cart_item {
      width: 100%;
      margin: 20px 0;
      border: none;
      .redeem_cart_item_close {
        display: none;
      }
      .redeem_cart_item_text_desc {
        display: none;
      }
      .redeem_cart_item_text_size {
        display: block;
        font-family: $font--cta;
      }
      .redeem_cart_item_text_qty {
        display: block;
        font-family: $font--cta;
      }
    }
  }
  .redeem_checkout_cart_summary,
  .redeem_checkout_cart_shipping,
  .redeem_checkout_cart_btns {
    width: 100%;
    padding: 10px 30px;
    padding-right: 35%;
    border-top: solid 1px $color-light-gray;
    h4 {
      font-family: $font--subheading;
      font-size: 26px;
      text-transform: uppercase;
      margin: 10px 0;
      font-weight: normal;
    }
    a {
      font-size: 14px;
      text-decoration: underline;
    }
    .redeem_checkout_cart_btn {
      padding: 0 90px;
    }
  }
  .redeem_checkout_cart_btns a {
    display: block;
    /* float: left; */
    padding-top: 15px;
  }
  .redeem_checkout {
    width: 70%;
    float: right;
    margin: 0 20px 20px;
    background-color: $color-silver;
    .white_border {
      border: solid 1px $color-white;
      margin: 1px;
      padding: 20px 30px;
      p {
        font-family: $font--subheading;
        font-size: 28px;
        i {
          float: right;
          font-family: $font--text;
        }
        border-bottom: solid 2px $color-light-gray;
        padding-bottom: 20px;
      }
      b {
        display: block;
        font-family: $font--subheading;
        font-size: 24px;
        font-weight: normal;
      }
      input {
        float: right;
        margin-top: -40px;
        padding: 0 70px;
      }
      .redeem_tier2 {
        font-size: 14px;
      }
    }
  }
  #loyalty_redeem {
    input.error,
    select.error {
      border: 1px solid $color_red;
    }
  }
  .redeem_checkout_cart_shipping {
    font-size: 18px;
    input[type='text'],
    input[type='tel'] {
      width: 45%;
      float: left;
      margin: 15px 0;
      border-color: $color-black;
    }
    input[type='tel'].error {
      border: 1px solid $color_red;
    }
    .state_select {
      width: 45%;
      float: right;
      margin: 15px 0;
    }
    input[name='LAST_NAME'] {
      float: right;
    }
    input[name='POSTAL_CODE'],
    input[type='tel'] {
      margin-right: 55%;
    }
    input[name='ADDRESS1'],
    input[name='ADDRESS2'] {
      width: 100%;
    }
    ul.error_messages_display {
      background-color: $transparent--white;
      font-weight: normal;
    }
  }
  .redeem_checkout_confirm {
    padding: 20px 20px 10px;
    h3 {
      font-family: $font--subheading;
      font-size: 36px;
      margin: 0;
    }
    a {
      font-family: $font--cta;
      font-size: 14px;
      text-decoration: underline;
    }
    .redeem_checkout_confirm_head {
      border-bottom: solid 1px $color-black;
      padding-bottom: 20px;
      margin-bottom: 30px;
    }
    border-bottom: solid 2px $color-black;
  }
  .redeem_boutique {
    .product-brief__cta-spp-link {
      display: none;
    }
  }
}
/* Toggle tier views */
.tier_1_show,
.tier_2_show,
.tier_3_show {
  display: none;
}

.tier_1 .tier_1_show,
.tier_2 .tier_2_show,
.tier_3 .tier_3_show {
  display: inline-block;
}
/* pop-up overlay */
.loyalty_conf_leave {
  border: solid 2px $color-white;
  #cboxClose {
    display: none;
  }
  #cboxContent {
    background: none;
    border: none;
    .loyalty_conf_leave_right {
      background-color: $color-white;
      float: right;
      width: 100%;
      height: 100%;
      text-align: center;
    }
    h6 {
      color: $color-black;
      font-family: $font--subheading;
      font-size: 24px;
      margin: 1em;
    }
    p {
      font-style: italic;
      color: $color-dark-gray;
      margin-top: 0;
    }
    .button {
      padding: 0 50px;
      margin: 0 10px;
    }
  }
  /* background: url(/media/export/cms/loyalty/popup_bg.jpg) center top; */
}
/* temp snipp layout */
.loyalty_offer_snipp {
  .loyalty_offer_title_snipp {
    text-transform: uppercase;
    font-size: 24p;
    font-family: $font--futura-medium;
    margin-bottom: 20px;
  }
  .loyalty_offer_blurb_snipp {
    font-family: $font--text;
    font-size: 14px;
    ul {
      list-style: initial;
      margin-left: 20px;
    }
  }
  .loyalty_offer_right_snipp {
    font-family: $font--text;
    font-size: 15px;
    margin-bottom: 10px;
  }
  select {
    width: 200px;
    height: 45px;
    padding: 12px;
    clear: both;
    margin: 10px auto;
    line-height: 1em;
    min-width: initial;
  }
  input {
    width: 198px;
  }
}

#shipping-panel {
  .section-loyalty-promotions .rewards_header {
    display: block !important;
    visibility: visible;
    @include h11;
  }
  .loyalty-join {
    margin-left: 18px;
  }
  .review-panel__loyalty-points {
    display: block;
    .rewards_icon_container {
      position: relative;
      display: inline-block;
      .icon.icon--my-origins {
        font-size: 72px;
        margin: 20px 20px 20px 30px;
      }
      .origins_reward_icon {
        position: absolute;
        text-transform: uppercase;
        font-family: $font--heading;
        font-size: 15px;
        top: 50px;
        left: 69px;
      }
    }
    .review-panel__loyalty-points__description {
      display: inline-block;
    }
    .review-panel__loyalty-points__earning {
      font-family: $font--futura-medium;
    }
    h3 {
      display: none;
    }
  }
  .loyalty-join .promotions-container {
    padding-bottom: 20px;
    .rewards_icon_container {
      position: relative;
      display: inline-block;
      .icon.icon--my-origins {
        font-size: 72px;
        margin: 5px 0;
      }
      .origins_reward_icon {
        position: absolute;
        text-transform: uppercase;
        font-family: $font--heading;
        font-size: 15px;
        top: 34px;
        left: 39px;
      }
    }
    .fs.loyalty_join {
      display: inline;
      margin: 13px 0 0 18px;
      vertical-align: top;
      .label-content {
        letter-spacing: 0.016em;
        font-size: 17px;
      }
    }
  }
}

#review-panel {
  .review-panel__loyalty-points {
    display: block;
    .rewards_icon_container {
      position: relative;
      display: inline-block;
      .icon.icon--my-origins {
        font-size: 72px;
        margin: 20px 20px 20px 30px;
      }
      .origins_reward_icon {
        position: absolute;
        text-transform: uppercase;
        font-family: $font--heading;
        font-size: 15px;
        top: 50px;
        left: 69px;
      }
    }
    .review-panel__loyalty-points__description {
      display: inline-block;
    }
    .review-panel__loyalty-points__earning {
      font-family: $font--futura-medium;
    }
    h3 {
      display: none;
    }
  }
  .email-and-sms-promotions {
    .checkout__panel--header {
      padding: 0;
    }
    .checkout__panel--content {
      padding: 0;
    }
  }
  .loyalty-join .promotions-container {
    padding-bottom: 20px;
    .rewards_icon_container {
      position: relative;
      display: inline-block;
      .icon.icon--my-origins {
        font-size: 72px;
        margin: 5px 0;
      }
      .origins_reward_icon {
        position: absolute;
        text-transform: uppercase;
        font-family: $font--heading;
        font-size: 15px;
        top: 34px;
        left: 39px;
      }
    }
    .fs.loyalty_join {
      display: inline;
      margin: 13px 0 0 18px;
      vertical-align: top;
      .label-content {
        letter-spacing: 0.016em;
        font-size: 17px;
      }
    }
  }
}

#confirmation-page {
  .loyalty-join-panel {
    margin-left: 18px;
    .button {
      width: 30%;
    }
  }
  .review-panel__loyalty-points {
    display: none;
  }
}
/* Loyalty Opt-Out Popup */
.loyalty_signout_form_container {
  .loyalty_signout-form__fieldset {
    padding: 30px;
    max-width: 502px;
    min-height: 331px;
    margin-right: 0;
    text-align: center;
    background-image: url('/media/images/loyalty/popup.jpg');
    background-repeat: no-repeat;
    background-position: right bottom;
    @include breakpoint($small-down) {
      padding: 0 0 30px;
      background-position: right -43px bottom 92px;
      margin: 0 auto;
    }
    .header.account-page__section--subheading {
      margin-bottom: 18px;
      margin-top: 18px;
      font-size: 28px;
      text-transform: capitalize;
      @include breakpoint($small-down) {
        margin: 18px 4px 11px 5px;
      }
    }
    ul.error_messages_display {
      background-color: unset;
      font-weight: normal;
    }
    .cancel_header {
      font-style: normal;
      margin-bottom: 23px;
      letter-spacing: 0.03em;
      color: $color-black;
      @include breakpoint($small-down) {
        margin-bottom: 16px;
      }
    }
    .loyalty_signout-form__item {
      font-size: 14px;
      margin-bottom: 20px;
      @include breakpoint($small-down) {
        line-height: 1.5;
      }
      .label {
        .label-content {
          display: inline;
          margin-left: 0;
          @include breakpoint($small-down) {
            display: inline-block;
            text-align: left;
            width: 245px;
          }
        }
      }
    }
    .cancel_action {
      margin-top: 95px;
      @include breakpoint($small-down) {
        margin-top: 113px;
      }
      .loyalty_signout-form__submit {
        width: 146px;
      }
      #continue-btn.loyalty_signout-form__submit {
        margin-right: 48px;
        @include breakpoint($small-down) {
          margin-right: 0;
        }
      }
    }
    .loyalty_cancel_ok.top {
      border-bottom: 1px solid $color-black;
      text-align: left;
      a {
        font-family: $font--subheading;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin: 14px 12px 11px;
        font-size: 28px;
        display: inline-block;
        width: 169px;
        background-image: url('/media/images/loyalty/arrow_back_to_account.jpg');
        background-repeat: no-repeat;
        background-position: left center;
        text-align: right;
      }
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
}

.loyalty_signout_sorry_container {
  .loyalty_signout-sorry__fieldset {
    padding: 30px 70px;
    max-width: 502px;
    min-height: 331px;
    margin-right: 0;
    text-align: center;
    background-image: url('/media/images/loyalty/popup.jpg');
    background-repeat: no-repeat;
    background-position: right bottom;
    @include breakpoint($small-down) {
      padding: 0 0 70px;
      background-position: right -43px bottom 36px;
      margin: 0 auto;
    }
    .header {
      font-size: 28px;
      margin-bottom: 18px;
      @include breakpoint($small-down) {
        margin: 17px 8px 10px 13px;
      }
    }
    .cancel_header {
      font-style: italic;
      margin-bottom: 23px;
      letter-spacing: 0.0295em;
      color: $color-dark-gray;
      @include breakpoint($small-down) {
        margin-bottom: 12px;
      }
    }
    .join_back_anytime {
      font-size: 14px;
      text-align: left;
      @include breakpoint($small-down) {
        width: 310px;
        margin: 0 auto;
      }
    }
    .can_always_join {
      letter-spacing: 0.018em;
      margin-bottom: 18px;
      margin-left: 5px;
      @include breakpoint($small-down) {
        line-height: 1.5;
        margin-bottom: 21px;
        margin-left: 7px;
      }
    }
    .loyalty_cancel_us {
      letter-spacing: 0.035em;
      margin-left: 4px;
      @include breakpoint($small-down) {
        line-height: 1.5;
        margin-left: 6px;
      }
    }
    .loyalty_cancel_ok {
      @include breakpoint($small-down) {
        text-align: left;
      }
      a {
        width: 150px;
        margin-top: 60px;
        @include breakpoint($small-down) {
          font-family: $font--subheading;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          margin: 10px 12px 11px;
          font-size: 28px;
          display: inline-block;
          width: 169px;
          background-image: url('/media/images/loyalty/arrow_back_to_account.jpg');
          background-repeat: no-repeat;
          background-position: left center;
          text-align: right;
        }
      }
    }
    .loyalty_cancel_ok.top {
      border-bottom: 1px solid $color-black;
      a {
        margin-top: 14px;
      }
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
}
/* Loyalty Signin Redeem Point section */
.loyalty__panel__points {
  .mobile_tier_level {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  .loyalty_h3 {
    clear: right;
    font-family: $font--heading;
    font-size: 24px;
    font-weight: 400;
    line-height: 2.3em;
    margin: 0;
    text-align: center;
    width: 100%;
  }
  .loyalty_points {
    border-bottom: 1px solid $color-black;
    border-top: 1px solid $color-light-gray;
    @include breakpoint($medium-up) {
      display: inline-block;
    }
    .loyalty_point {
      border-left: 1px solid $color-light-gray;
      float: left;
      min-height: 180px;
      position: relative;
      text-align: center;
      width: 33%;
      padding-bottom: 30px;
      h5 {
        font-family: $font--heading;
        font-size: 18px;
        font-weight: 100;
        letter-spacing: 0;
        margin: 5px;
        text-align: center;
      }
      .description {
        font-size: 15px;
        padding: 0 40px;
        color: $color-dark-gray;
      }
      img {
        padding: 20px 0 0 20px;
      }
      p {
        font-family: $font--cta;
        font-size: 14px;
        color: $color-mid-gray;
      }
      &.loyalty_points-sample {
        border-left: 0;
      }
      &.loyalty_points-full-size img {
        padding: 20px 0 10px 20px;
      }
      &.loyalty_points-double-size img {
        padding: 20px 0 30px 20px;
      }
    }
  }
}
/* history page changes */
.loyalty__panel__transactions {
  &__content {
    margin: 0;
    .expando-sub-menu {
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    .expando-menu-trigger {
      &:after {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
  }
  &__list__column-activity--level,
  &__reached-name {
    font-weight: bold;
    text-transform: uppercase;
    font-family: $font--subheading;
    font-size: 22px;
    padding: 10px 0 0 15px;
  }
  &__title {
    font-family: $font--subheading;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: normal;
    font-size: 24px;
  }
  &__current-tier {
    display: none;
  }
  &__bottomborder {
    border-bottom: 1px solid $color-black;
    padding-bottom: 5px;
    @include breakpoint($small-down) {
      border-bottom: none;
    }
  }
  &__available-amount {
    font-size: 50px;
    font-family: $font--cta;
    color: $color-black;
    padding-top: 5px;
    @include breakpoint($small-down) {
      font-size: 30px;
      padding-top: 0px;
    }
    p {
      display: none;
    }
  }
  &__lifepoint {
    font-family: $font--text;
    font-weight: normal;
    font-style: italic;
    font-size: 18px;
    margin: 25px 0 0 0;
    @include breakpoint($small-down) {
      font-size: 16px;
      margin: 0;
    }
  }
  &__rewards-history {
    width: 100%;
    float: left;
    margin: 25px 0 0px 25px;
    padding: 0 70px 0 0;
    min-height: 95px;
    @include breakpoint($small-down) {
      width: 100%;
      border-right: none;
      margin: 0px;
      padding: 10px;
      min-height: auto;
    }
    p {
      margin: 15px 0 0 0;
      span.reward-font {
        font-family: $font--futura-medium;
        @include breakpoint($small-down) {
          font-weight: normal;
        }
      }
    }
    .expando-menu-trigger {
      &:after {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
  }
  &__all-points {
    margin: 25px 0 0;
    text-align: center;
    float: right;
    width: 32%;
    @include breakpoint($small-down) {
      width: 90%;
      border-top: 1px solid $color-light-gray;
      margin: 0 5%;
      padding: 10px 0 0 0;
    }
  }
  &__list {
    .point-act {
      border-bottom: 1px $color-light-gray solid;
      padding: 15px 25px;
      border-top: 1px $color-black solid;
      margin-top: 15px;
      @include breakpoint($small-down) {
        border-bottom: none;
        padding: 15px 25px 10px 10px;
        margin-top: 18px;
      }
    }
    &__column-date {
      width: 12%;
      @include breakpoint($small-down) {
        width: 36%;
      }
    }
    &__column-activity {
      width: 70%;
      &__transaction-link__order-id {
        text-decoration: underline;
        padding: 0 10px 0 0;
      }
    }
    &__column-points {
      width: 12%;
      @include breakpoint($small-down) {
        width: auto;
      }
    }
  }
  &__table {
    margin: 2%;
    width: 98%;
    font-family: $font--cta;
    font-size: 14px;
    table-layout: auto;
    @include breakpoint($medium-up) {
      table-layout: fixed;
    }
    @include breakpoint($small-down) {
      width: 100%;
      margin: 0;
    }
    thead {
      background-color: $color-silver;
      th {
        font-weight: normal;
        vertical-align: middle;
      }
    }
    tbody {
      tr {
        border-bottom: 1px $color-light-gray solid;
        &:last-child,
        &.expando-menu__parent--expanded {
          border-bottom: none;
        }
        td div {
          display: inline;
        }
      }
      .expando-sub-menu {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
    .loyalty__panel__transactions__list__column-activity {
      display: none;
      @include breakpoint($medium-up) {
        display: table-cell;
      }
      &__points {
        display: none;
      }
    }
  }
}
/* Loyalty Terms and conditions */
.loyalty_header_content {
  .content-header__text {
    max-width: 730px;
  }
}

.loyalty_terms_conditions {
  &_section_title {
    font-family: $font--heading;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: normal;
    font-size: 24px;
    border-bottom: 1px $color-black solid;
    text-align: center;
    padding: 10px 0 25px 0;
  }
  &_section_sub_title {
    font-family: $font--futura-medium;
    text-align: center;
    font-size: 18px;
  }
  &_wrapper {
    width: 80%;
    margin: 0 auto;
    p {
      font-size: 16px;
      letter-spacing: 0.001em;
    }
  }
  &_content {
    font-size: 14px;
    padding: 0 0 20px 0;
  }
  &_heading {
    font-style: italic;
    font-family: $font--futura-medium;
    font-size: 18px;
    letter-spacing: 0.001em;
  }
}
/* Loyalty Terms and conditions Mobile */
.device-mobile {
  .loyalty_terms_conditions {
    &_sec_wrapper {
      border-bottom: solid 1px $color-black;
      padding: 0.6em 0 0.4em;
      text-align: center;
      &_section_title {
        font-family: $font--heading;
        font-size: 22px;
        border-bottom: none;
        padding: 0px;
        margin: 0px;
      }
      &_second_title {
        font-style: italic;
        font-size: 17px;
        letter-spacing: 0.001em;
        margin: 0;
      }
    }
    &_content {
      word-spacing: 0.3em;
    }
    &_wrapper {
      width: 100%;
      padding: 0 15px;
    }
    &_section_sub_title {
      padding: 8px 40px 0;
    }
  }
}
/* FAQ PC Page changes */
.loyalty__panel__faq,
#loyalty__page__faq {
  .field-content {
    min-height: 610px;
  }
}

.loyalty-header {
  font-family: $font--heading;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: normal;
  font-size: 24px;
  border-bottom: 1px $color-gray solid;
  text-align: center;
  padding: 5px 0 20px 0;
}

.faq_block_pc {
  padding: 0 40px;
  border-bottom: 1px solid $color-light-gray;
  margin: 18px 20px 0 20px;
  @include breakpoint($xxsmall-down) {
    padding: 0 12px;
    margin: 18px 0 0 0;
  }
  .cs-menu__children {
    padding: 0;
  }
  .acc_question {
    font-family: $font--text;
    letter-spacing: 0.025em;
    font-weight: normal;
    line-height: 1.2;
    font-style: italic;
    font-size: 25px;
    @include breakpoint($xxsmall-down) {
      font-size: 20px;
    }
    &::after {
      content: '';
      display: block;
      background: $color-gray;
      position: relative;
      width: 35px;
      height: 1px;
      margin: 12px 0 0 0;
    }
  }
  .acc_answer {
    font-family: $font--text;
    letter-spacing: 0.025em;
    font-weight: normal;
    line-height: 1.2;
    font-size: 20px;
    @include breakpoint($xxsmall-down) {
      font-size: 16px;
    }
  }
}
/* Confirmation page Loyalty section */
.checkout-confirmation-page {
  .sign-in-page.checkout_registered_no_loyalty {
    .account-main-content {
      padding-right: 0;
      .signin_promo_content_sub_right.earn_benefits.checkout_disable {
        display: none;
      }
      .signin_promo_content_sub_left.start_gaining {
        width: 40%;
        padding-left: 45px;
        h5 {
          font-size: 18px;
          letter-spacing: 0;
          word-spacing: -5px;
          margin-bottom: 9px;
          i {
            font-family: $font--heading;
            font-style: normal;
            font-size: 20px;
            padding-top: 2px;
            letter-spacing: 0;
          }
        }
        img {
          display: none;
        }
        p {
          font-size: 17px;
          letter-spacing: 0.03em;
          line-height: 1.45;
          padding: 0 20px;
          margin: 0;
        }
      }
    }
  }
  .sign-in-page.checkout_anonymous {
    .account-main-content {
      padding-right: 0;
      .signin_promo_content_sub_right.earn_benefits.checkout_disable,
      .signin_promo_content_sub_left.start_gaining {
        display: none;
      }
      .signin_promo_content_sub_right.track_your_account {
        display: block;
        float: left;
        width: 40%;
        text-align: left;
        padding-left: 62px;
        padding-bottom: 0;
        margin-top: 15px;
        h5.track_your_account {
          font-family: $font--heading;
          font-size: 17px;
          letter-spacing: 0;
          margin-bottom: 9px;
          line-height: 1;
          text-transform: uppercase;
        }
        p {
          letter-spacing: 0.04em;
          padding: 0;
          font-size: 16px;
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 15px;
          line-height: 27px;
        }
      }
    }
  }
  .sign-in-page {
    border-bottom: none;
    .account-main-content {
      border-bottom: none;
      .signin_promo_content_sub_left.exclusive_benefits.checkout_enable {
        display: block;
        width: 40%;
        padding-left: 55px;
        h5 {
          font-size: 18px;
          letter-spacing: 0.045em;
          margin-bottom: 8px;
          text-align: left;
        }
        p {
          font-size: 16px;
          letter-spacing: 0.049em;
          line-height: 26px;
          padding-left: 11px;
          text-align: left;
        }
      }
      .signin_promo_content {
        border-right: none;
        .signin_promo_content_header {
          display: none;
        }
        .signin_promo_content_sub {
          background: none;
          padding-bottom: 12px;
          .signin_promo_content_sub_left {
            padding-top: 23px;
          }
          .signin_promo_content_sub_right {
            .signin_promo_content_sub_right_ps {
              text-align: left;
              letter-spacing: 0;
              margin-left: 105px;
              margin-top: 21px;
              span {
                margin-left: 35px;
              }
            }
          }
        }
      }
    }
  }
  .join_rewards_tout {
    background: #f5f5f5 url('/media/export/cms/loyalty/flower.png') no-repeat right 5px bottom;
  }
  .join_origins_container {
    font-size: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-family: $font--subheading;
    margin-left: 110px;
    padding-top: 35px;
    .join_origins_header {
      margin-left: 20px;
    }
  }
  .rewards_icon_container {
    position: relative;
    display: inline-block;
    .icon.icon--my-origins {
      font-size: 57px;
      vertical-align: top;
    }
    .origins_reward_icon {
      position: absolute;
      text-transform: uppercase;
      font-family: $font--heading;
      font-size: 11px;
      top: 22px;
      left: 31px;
    }
  }
  .loyalty_join_container {
    background-color: $color-light-gray;
    padding-bottom: 15px;
    .loyalty_join {
      margin: 0;
      padding: 15px 0;
      margin-bottom: 20px;
    }
  }
  #confirmation-panel {
    border-bottom: 1px solid $color-light-gray;
  }
  #registration-panel {
    border-top: none;
    border-bottom: 1px solid $color-light-gray;
    .rewards_header {
      @include h11;
    }
    .fs.loyalty_join {
      display: inline;
      margin: 0 0 0 20px;
      vertical-align: top;
    }
    .registration-panel__create-account {
      display: none;
    }
    .loyalty-join {
      margin-bottom: 30px;
    }
    .email-and-sms-promotions {
      display: none;
      .checkout__panel--header,
      .checkout__panel--content {
        padding: 0;
      }
    }
  }
}

#loyalty__page__earn .loyalty_mobile_nav {
  margin: 0;
  @include breakpoint($medium-up) {
    margin: 0 0 20px;
  }
}

// Redeem Chekcout Section For Mobile
//Hide Short description for PC
.loyalty__panel__redeem {
  .redeem_cart_item_text_short_desc {
    display: none;
  }
  .product-grid {
    &.product-grid-v3 {
      .product-grid__item {
        @include breakpoint($medium-up) {
          margin-bottom: 14px;
        }
        .product-brief {
          &__description {
            @include breakpoint($medium-up) {
              min-height: 77px;
            }
          }
          &__sized-detail {
            @include breakpoint($medium-up) {
              margin: 4px 0;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .loyalty__panel__redeem {
    .redeem_cart_item_text_desc {
      display: none;
      overflow: visible;
    }
    .redeem_cart_item_text_short_desc {
      display: block;
      .fa-chevron-down {
        display: inline;
      }
    }
    .loyalty_boutique_breadcrumb {
      padding: 9px 16px;
      border-bottom: 1px solid #c5c5c5;
    }
    .loyalty_h3 {
      border-bottom: 1px solid #c5c5c5;
      font-size: 20px;
      margin-top: 4px;
      padding-bottom: 5px;
      i {
        font-size: 14.5px;
        font-weight: 400;
        line-height: 1.9em;
        margin-top: -9px;
      }
    }
    .product-brief__img {
      margin-top: 0;
    }
    .redeem_required_mark {
      font-size: 16px;
      margin-bottom: 15px;
      margin-top: 30px;
    }
    .redeem_cart_item {
      border-bottom: 1px solid #c5c5c5;
      float: left;
      margin: 10px 0;
      padding: 15px;
      position: relative;
      width: 100%;
      display: inline-block;
      .redeem_cart_item_text h5 {
        font-size: 21px;
        font-weight: normal;
        margin: 0 0 10px;
      }
      .redeem_cart_item_text {
        width: 80%;
        float: left;
        min-height: 100px;
        width: 70%;
        padding-left: 0;
        .redeem_cart_item_text_desc {
          min-height: 3.6em;
          height: initial;
          line-height: 1.2em;
          overflow: hidden;
          .fa-chevron-up {
            display: inline;
          }
        }
      }
      img {
        float: left;
        margin-left: 0;
        margin-right: 5%;
        overflow: hidden;
        position: relative;
        top: 3px;
        width: 25%;
        max-width: 80px;
      }
    }
    .redeem_cart_item.redeem_cart_item_last {
      margin-bottom: 0;
    }
    .redeem_checkout {
      background-color: $color-white;
      float: none;
      padding: 0 15px 15px;
      margin: 0;
      width: 100%;
      display: inline-block;
      .white_border {
        margin: 0;
        padding: 0;
        position: relative;
        input {
          float: none;
          margin-top: 0;
          width: 100%;
          margin-bottom: 15px;
        }
        div {
          font-size: 13px;
          margin-bottom: 15px;
          width: 60%;
        }
        b {
          font-weight: normal;
        }
        p {
          border-bottom: 1px solid #c5c5c5;
          font-size: 28px;
          font-weight: normal;
          margin: 5px 0 15px;
          padding-bottom: 10px;
          text-align: center;
          i {
            font-family: $font--text;
            position: absolute;
            right: 0px;
            top: 63px;
          }
        }
      }
    }
    .redeem_cart {
      border-top: 1px solid #c5c5c5;
    }
    .product-grid {
      margin: 0;
    }
    .product-grid__content {
      margin: 15px;
    }
    .loyalty_mobile_nav-active {
      margin-bottom: 0px;
    }
    .redeem_checkout_cart_summary,
    .redeem_checkout_cart_shipping,
    .redeem_checkout_cart_btns {
      padding: 15px;
    }
    .redeem_checkout_cart_btn,
    .redeem_checkout_cart_btn,
    .redeem_checkout_cart_btn {
      width: 100%;
    }
    .redeem_checkout_cart_shipping {
      font-size: 16px;
    }
    .redeem_checkout_cart_shipping {
      input[type='text'],
      input[type='tel'] {
        width: 100%;
        margin: 0 0 13px;
      }
      .state_select {
        width: 100%;
      }
    }
    .redeem_checkout_cart_items {
      border: none;
      .redeem_cart_item {
        .redeem_cart_item_text_short_desc {
          display: none;
        }
        margin: 0;
        padding: 20px 15px;
        border-bottom: 1px solid #c5c5c5;
        .redeem_cart_item_text {
          min-height: auto;
          p {
            &.redeem_cart_item_text_qty,
            &.redeem_cart_item_text_size {
              font-size: 14px;
              margin: 0;
              font-family: $font--cta;
            }
            &.redeem_cart_item_text_size {
              margin-top: 10px;
            }
          }
        }
        img {
          float: left;
          margin-left: 5%;
          margin-right: 5%;
          verflow: hidden;
          position: relative;
          top: 0;
          width: 20%;
        }
      }
    }
    .product-brief,
    .favorites-product-info,
    .recommended-item__product-info {
      height: 100%;
      padding: 0 10px 0 0;
      position: relative;
    }
    .redeem_checkout_cart_summary {
      border: none;
      p {
        font-family: $font--cta;
        font-size: 14px;
        margin: 0 0 3px;
      }
    }
    .redeem_checkout_cart_shipping {
      p {
        font-size: 16px;
      }
    }
    .redeem_checkout_cart_summary,
    .redeem_checkout_cart_shipping,
    .redeem_checkout_cart_btns {
      h4 {
        font-size: 22px;
        font-weight: normal;
        margin: 0 0 5px;
      }
      a {
        font-family: $font--cta;
      }
    }
    .redeem_checkout_cart_add_address {
      p {
        font-family: $font--cta;
        line-height: 17px;
        margin: 0 0 10px;
      }
    }
    .redeem_checkout_cart_btns {
      a {
        padding-bottom: 15px;
        padding-top: 25px;
      }
    }
    .product-brief__cta--shop-now {
      margin-bottom: 10px;
    }
    .carousel-dots {
      height: auto;
      margin: 0 0 10px;
      padding: 0 15px;
    }
    .redeem_checkout_confirm {
      border: none;
      padding: 0;
      .redeem_checkout_confirm_head {
        h3 {
          font-size: 21px;
          font-weight: normal;
          margin: 0 0 10px;
          text-transform: uppercase;
        }
        margin-bottom: 5px;
        border: none;
        padding: 15px 20px 10px;
      }
      .redeem_checkout_confirm_details {
        border-top: 1px solid #c5c5c5;
        padding: 10px;
        p {
          padding: 0 5px;
        }
      }
    }
    p.loyalty_us_ship {
      font-size: 14px;
    }
  }
}
/* Redeem an Earn Mobile section styling */
@include breakpoint($small-down) {
  .loyalty__panel__offers__offer {
    margin-top: 15px;
    .loyalty__panel__redeem & {
      border-bottom: solid 1px $color-black;
    }
    .loyalty_offer {
      &_left {
        .loyalty__panel__redeem & {
          margin: 10px 2% 0;
          border-bottom: none;
          padding-bottom: 10px;
        }
      }
      &_right {
        .loyalty__panel__redeem & {
          margin: 3px 4%;
          padding-top: 15px;
          padding-bottom: 19px;
          border-top: 1px solid $color-light-gray;
        }
      }
      .loyalty__panel__redeem &_availability,
      .loyalty__panel__redeem &_not_available {
        font-size: 19px;
        text-align: center;
        letter-spacing: 0.08em;
      }
      #loyalty__page__earn .loyalty_offer_form .loyalty_offer_blurb {
        letter-spacing: 0.018em;
      }
      #loyalty__page__earn &_form {
        margin-top: 2px;
      }
      .loyalty__panel__redeem &_type {
        letter-spacing: 0.071em;
        font-size: 18px;
        line-height: 28px;
      }
      &_title {
        .loyalty__panel__redeem & {
          font-size: 28px;
          line-height: 20px;
          letter-spacing: 0.058em;
          padding-top: 20px;
        }
      }
      &_value {
        .loyalty__panel__redeem & {
          display: block;
          font-size: 23px;
          color: $color-mid-gray;
          text-transform: uppercase;
          letter-spacing: 0.025em;
        }
      }
      &_blurb {
        .loyalty__panel__redeem & {
          font-size: 14px;
          margin-left: 9px;
          letter-spacing: 0.03em;
        }
      }
      #loyalty__page__earn {
        padding-top: 14px;
        padding-bottom: 11px;
        margin: 3px 10px;
        border-top: 1px solid $color-light-gray;
        .category_offer_container_1 {
          margin-left: 10px;
        }
        .category_offer_container_2 {
          display: inline;
        }
        &.category_offer_thumbs {
          margin: 0;
          padding-top: 5px;
          padding-bottom: 0;
          display: inline-block;
          border-top: none;
          img {
            float: left;
            height: 150px;
          }
        }
      }
      #loyalty__page__earn &_type {
        font-size: 19px;
        letter-spacing: 0.068em;
      }
      #loyalty__page__earn &_title {
        font-size: 25px;
        letter-spacing: 0.091em;
        line-height: 1;
      }
      #loyalty__page__earn &_blurb {
        font-size: 14px;
        letter-spacing: 0.008em;
        margin: 9px auto 0;
        width: 245px;
      }
      #loyalty__page__earn &_earned {
        font-size: 20px;
        letter-spacing: 0.05em;
      }
      #loyalty__page__earn &_points {
        font-size: 30px;
        line-height: 25px;
      }
      #loyalty__page__earn &_points_type {
        font-size: 12px;
        line-height: 22px;
      }
      #loyalty__page__earn &_availability,
      #loyalty__page__earn &_not_available {
        font-size: 18px;
        letter-spacing: 0.09em;
      }
      #loyalty__page__earn &_note {
        letter-spacing: 0.04em;
        line-height: 17px;
        margin-top: 3px;
      }
    }
    &.offer_1 .loyalty_offer {
      .loyalty__panel__redeem &_image {
        margin: 0 auto 15px;
        float: none;
      }
      .loyalty__panel__redeem &_value {
        line-height: 24px;
      }
    }
    &.offer_2 .loyalty_offer {
      .loyalty__panel__redeem &_image {
        margin: 0 auto 15px;
        float: none;
      }
      .loyalty__panel__redeem &_title {
        letter-spacing: 0.065em;
      }
      .loyalty__panel__redeem &_value {
        line-height: 20px;
      }
    }
    &.offer_3 {
      border-bottom: none;
    }
    &.offer_3 .loyalty_offer {
      .loyalty__panel__redeem &_image {
        margin: 0 auto 15px;
        float: none;
      }
      .loyalty__panel__redeem &_type {
        padding-top: 1px;
      }
      .loyalty__panel__redeem &_value {
        line-height: 17px;
      }
      .loyalty__panel__redeem &_blurb {
        letter-spacing: 0.025em;
      }
    }
    .loyalty__panel__redeem & .button {
      margin: 0;
    }
    .loyalty_offer_expires {
      line-height: 20px;
      margin-bottom: 3px;
    }
    .redeem_tier1_items {
      margin: 0 auto;
      width: 290px;
    }
    .loyalty_offer_sku_array_txt {
      font-family: $font--cta;
      font-size: 14px;
      letter-spacing: 0.06em;
    }
    .loyalty_offer_sku_array_skus_item {
      font-family: $font--cta;
      margin: 17px 0 0;
      display: inline-block;
      vertical-align: top;
      letter-spacing: 0.025em;
      width: 80px;
    }
  }
}

#loyalty__page__redeem .loyalty__panel__redeem {
  .your_status.mobile_redeem_header {
    display: block;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}
/* Loyalty Enrollment page */
@include breakpoint($small-down) {
  .loyalty__panel__points {
    .mobile_tier_level {
      font-family: $font--cta;
      letter-spacing: 0.04em;
      font-weight: normal;
      line-height: 1;
      font-size: 14px;
      margin: 6px auto 0;
      display: block !important;
      .sliderLabel {
        color: $color-green;
        text-decoration: none;
      }
      li {
        display: inline-block;
        text-transform: uppercase;
        text-decoration: underline;
        padding: 10px 0;
        vertical-align: top;
        letter-spacing: 0.045em;
        &.dot {
          text-decoration: none;
          font-size: 28px;
          padding: 0;
        }
        &.tier_1 {
          margin-left: 32px;
          margin-right: 10px;
        }
        &.tier_2 {
          margin-left: 12px;
          margin-right: 12px;
        }
        &.tier_3 {
          margin-left: 14px;
        }
      }
      display: block;
      padding-top: 14px;
      letter-spacing: 0.05em;
      width: 320px;
    }
    .loyalty_points {
      border-top: none;
    }
    .loyalty_h3 {
      font-size: 19px;
      border-bottom: 1px solid $color-medium-gray;
    }
    .loyalty_points .loyalty_point {
      h5 {
        font-size: 17px;
        margin: 0;
        margin-top: -3px;
        letter-spacing: 0em;
        margin-bottom: 8px;
      }
      .description {
        font-size: 14px;
        letter-spacing: 0.03em;
      }
      &.loyalty_points-full-size img {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
  .loyalty__panel__redeem {
    .your_status.mobile_redeem_header {
      display: none;
    }
    .mobile_tier_level {
      font-family: $font--cta;
      letter-spacing: 0.04em;
      font-weight: normal;
      line-height: 1;
      font-size: 14px;
      margin: 6px auto 0;
      display: block !important;
      .sliderLabel {
        color: $color-green;
        text-decoration: none !important;
      }
      li {
        display: inline-block;
        text-transform: uppercase;
        text-decoration: underline;
        padding: 10px 0;
        vertical-align: top;
        letter-spacing: 0.045em;
        &.tier_1 {
          margin-left: 23px;
          margin-right: 16px;
        }
        &.dot {
          text-decoration: none;
          font-size: 28px;
          padding: 0;
        }
        &.tier_2 {
          margin-left: 14px;
          margin-right: 16px;
        }
        &.tier_3 {
          margin-left: 13px;
        }
      }
    }
    .loyalty_statuses {
      border-bottom: solid 1px $color-black;
      border-top: none;
      .loyalty_status {
        padding-left: 87px;
        overflow: hidden;
        text-align: center;
        img {
          bottom: 23px;
          left: -7px;
        }
        h5.loyalty_status_header,
        .at-h5-loyalty-status {
          font-size: 19px;
          margin: 12px 0 0 !important;
        }
        p {
          margin-left: 0;
          margin-top: 3px;
          letter-spacing: 0.05em;
        }
      }
    }
    .mobile_tier_level li.tier_3 {
      margin-left: 13px;
    }
  }
  .loyalty_points {
    .slick-next {
      right: 0;
      &:before {
        content: '';
        width: 15px;
        height: 12px;
        background: url('/media/export/cms/loyalty/right.png');
        right: 13px;
        top: -53px;
      }
    }
    .slick-prev {
      left: 0;
      &:before {
        content: '';
        width: 15px;
        height: 12px;
        background: url('/media/export/cms/loyalty/left.png');
        left: 20px;
        top: -53px;
      }
    }
  }
  .loyalty_statuses {
    .slick-next {
      right: 0;
      &:before {
        content: '';
        width: 15px;
        height: 12px;
        background: url('/media/export/cms/loyalty/right.png');
        right: 15px;
      }
    }
    .slick-prev {
      left: 0;
      &:before {
        content: '';
        width: 15px;
        height: 12px;
        background: url('/media/export/cms/loyalty/left.png');
        left: 15px;
      }
    }
  }
}
/* Redeem points print order */
@media print {
  .page-footer,
  .page-header .page-header__utils,
  #loyalty__page__redeem #loyalty__panel__also_like,
  .account-utilities__section .sidebar-menu .sidebar-menu__item_loyalty {
    display: none;
  }
  .account-utilities__section .sidebar-menu__item {
    list-style: none;
  }
}
/* Your status module */
.loyalty__panel__redeem {
  #loyalty__page__redeem & .Silver .loyalty_status.tier_1,
  #loyalty__page__redeem & .Gold .loyalty_status.tier_2,
  #loyalty__page__redeem & .Green .loyalty_status.tier_3,
  #loyalty__page__redeem & .tier_1 .loyalty_status.tier_1,
  #loyalty__page__redeem & .tier_2 .loyalty_status.tier_2,
  #loyalty__page__redeem & .tier_3 .loyalty_status.tier_3 {
    opacity: 1;
    h5.loyalty_status_header,
    .at-h5-loyalty-status {
      color: $color-black;
    }
  }
  .loyalty_statuses {
    .loyalty_status {
      min-height: auto;
      padding: 15px 15px 55px;
      width: 100%;
      margin-right: 0;
      @include breakpoint($medium-up) {
        width: 29.5%;
        margin-right: 16px;
      }
      @include breakpoint($medium-down) {
        width: 33%;
        margin-right: 12px;
      }
      @include breakpoint($small-down) {
        width: 100%;
        margin-right: 0;
      }
      #loyalty__page__redeem & {
        opacity: 0.3;
      }
      margin: 0;
      &.loyalty_status-silver {
        background: url('/media/images/loyalty/silver_border.jpg') no-repeat 0 0 / 100% 100%;
        margin-left: 30px;
        @include breakpoint($medium-down) {
          margin-left: 0;
        }
        h5.loyalty_status_header,
        .at-h5-loyalty-status {
          margin: 35px 0 0;
          line-height: 1;
        }
      }
      &.loyalty_status-gold {
        background: url('/media/images/loyalty/gold_border.jpg') no-repeat 0 0 / 100% 100%;
        width: 29.5%;
        h5.loyalty_status_header,
        .at-h5-loyalty-status {
          margin: 35px 0 0;
          line-height: 1;
        }
      }
      &.loyalty_status-green {
        background: url('/media/images/loyalty/green_border.jpg') no-repeat 0 0 / 100% 100%;
        h5.loyalty_status_header,
        .at-h5-loyalty-status {
          margin: 35px 0 0;
          line-height: 1;
        }
        ul {
          margin-bottom: 19px;
        }
        @include breakpoint($medium-down) {
          margin-right: 0;
        }
      }
      h5.loyalty_status_header,
      .at-h5-loyalty-status {
        font-family: initial;
        font-size: 55px;
        @include breakpoint($medium-down) {
          font-size: 35px;
        }
      }
      p {
        font-family: $font--text;
        font-size: 24px;
        font-style: italic;
        margin-bottom: 8px;
        @include breakpoint($medium-down) {
          font-size: 20px;
        }
      }
      .reach_next_level {
        margin-bottom: 15px;
        div {
          font-size: 15px;
          @include breakpoint($medium-down) {
            font-size: 14px;
          }
        }
      }
      ul {
        li {
          font-family: initial;
          font-size: 16px;
          @include breakpoint($medium-down) {
            font-size: 14px;
          }
          span {
            font-style: italic;
            font-size: 14px;
          }
        }
      }
    }
    .slick {
      &-arrow {
        top: 60%;
      }
      &-next {
        right: 5px;
      }
      &-prev {
        left: 5px;
      }
    }
  }
}
/* Enrollment Page */
.sign-in_content .loyalty-container {
  .basic-grid {
    padding-right: 0;
    .basic-tout__text {
      position: static; /* for safari */
      position: unset;
      transform: none;
      padding: 0;
      max-width: none; /* for safari */
      max-width: unset;
    }
    .basic-tout__text.basic-tout__text--asomatous {
      display: none;
    }
    &__item {
      min-height: auto;
      background-image: url('/media/images/loyalty/gold_border.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin: 0 0 10px 0;
      padding: 15px;
      @include breakpoint($medium-up) {
        margin: 0 auto 10px;
        float: none;
      }
      @include breakpoint($landscape-up) {
        width: 32%;
        float: left;
      }
      h3 {
        margin: 35px 0 0;
        line-height: 1;
        font-family: initial;
        font-size: 40px;
      }
      ul {
        li {
          font-family: initial;
          font-size: 16px;
          span {
            font-style: italic;
            font-size: 14px;
          }
        }
      }
    }
    &__item:first-child {
      background-image: url('/media/images/loyalty/silver_border.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @include breakpoint($landscape-up) {
        margin-right: 10px;
      }
    }
    &__item:last-child {
      background-image: url('/media/images/loyalty/green_border.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @include breakpoint($landscape-up) {
        margin-left: 10px;
      }
      ul {
        margin-bottom: 19px;
      }
    }
    p {
      font-family: $font--text;
      font-size: 22px;
      font-style: italic;
      margin-bottom: 8px;
    }
    .reach_next_level {
      margin-bottom: 15px;
      font-size: 15px;
    }
  }
}
/* In Store purchase(POS) changes */
.orders-page {
  &__content .in-store-orders {
    .orders-list__table--cell.order-points {
      display: none;
    }
  }
}

.sign-in_content_wrapper .hero-large {
  &:first-child {
    height: 310px;
    @include breakpoint($medium-up) {
      height: 350px;
    }
    .hero-large__image {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
}

.account-main-content__wrapper {
  .return-user,
  .new-account {
    padding: 13px 16px 10px 16px;
    @include breakpoint($medium-up) {
      padding: 13px 16px 22px 16px;
    }
  }
}
/* Guest user Order confirmation page */
#confirmation-page {
  .account-main-content {
    background: none;
  }
  .elc-user-state-anonymous & {
    .join_rewards_tout {
      background: $color-white url('/media/export/cms/loyalty/flower.jpg') no-repeat scroll right 5px bottom;
      display: none;
    }
  }
  #sign-in-confirmation-panel {
    .loyalty-join {
      .rewards_header,
      .rewards_icon_container {
        display: none;
      }
    }
  }
}
/* Signin Page FAQ Styling */
.faq {
  &_style {
    margin: 10px 0 10px 50px;
    display: list-item;
    list-style-type: circle;
  }
  &_no_style {
    display: block;
    margin-top: 10px;
  }
}

.page-content.loyalty-page {
  padding-top: 82px;
}
