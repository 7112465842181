@if $loyalty-redesign == true {
  .confirmation__page__loyalty {
    width: 100%;
    .purchase__description {
      @include breakpoint($medium-up) {
        font-size: 18px;
      }
      font-family: $font--futura-medium;
      letter-spacing: 1px;
      font-size: 23px;
      padding: 15px 0 20px;
    }
    .points__description {
      @include breakpoint($medium-up) {
        padding-right: 16px;
        font-size: 24px;
        letter-spacing: 0.5px;
      }
      margin-top: 10px;
      font-size: 30px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .signin__btn {
      @include breakpoint($medium-up) {
        letter-spacing: 0;
        font-size: 15px;
        padding: 12px;
      }
      display: block;
      font-size: 18px;
      padding: 18px;
      color: $color-white;
      background-color: $color-drak-black;
      border: 1px solid $color-drak-black;
      text-align: center;
      letter-spacing: 1px;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    .favorites {
      &__img {
        @include breakpoint($medium-up) {
          width: 50%;
          float: right;
          margin-bottom: 30px;
          height: 270px;
        }
        @include breakpoint($medium-portrait-only) {
          width: 100%;
          margin-bottom: 0;
        }
        background-image: url(/media/export/cms/loyalty/loyalty-popup.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 240px;
      }
      &__content {
        @include breakpoint($medium-up) {
          width: 50%;
          float: left;
          background-color: $color-light-gray;
          height: 270px;
          padding: 32px;
        }
        @include breakpoint($medium-portrait-only) {
          width: 100%;
          background: none;
        }
        height: auto;
        padding: 2% 4.5%;
        margin-bottom: 30px;
        .birthday__label {
          @include breakpoint($medium-up) {
            font-size: 15px;
          }
          font-size: 18px;
          font-family: $font--futura-medium;
        }
      }
    }
    &.logged-in-non-lyl {
      .loyalty_join {
        font-size: 14px;
        .enrolment__optin {
          padding-top: 0;
          a {
            text-decoration: underline;
          }
        }
        .messages__container__loyalty {
          ul {
            width: 100%;
            margin-bottom: 15px;
          }
        }
        .birth__date,
        .birth__month {
          margin-bottom: 25px;
        }
      }
      .birthday__label {
        padding-top: 20px;
      }
      .purchase__description {
        padding-bottom: 0;
      }
      .favorites__img {
        height: 370px;
        @include breakpoint($medium-up) {
          height: 560px;
        }
      }
      .favorites__content {
        height: auto;
        @include breakpoint($medium-up) {
          height: 560px;
        }
      }
    }
    &.guest-non-lyl {
      .terms__txt {
        font-size: 15px;
        font-weight: normal;
        padding-top: 0;
      }
    }
    &.logged-in-lyl,
    &.guest-user,
    &.guest-non-lyl {
      .favorites__img {
        height: 340px;
        @include breakpoint($medium-up) {
          height: 400px;
        }
      }
      .favorites__content {
        @include breakpoint($medium-up) {
          height: 400px;
        }
      }
    }
  }
  #confirmation-page {
    .confirmation-panel {
      &__confirm-text {
        &--signedin {
          @include breakpoint($medium-up) {
            font-size: 15px;
          }
          font-size: 14px;
        }
      }
      &__email-coming {
        font-size: 14px;
        @include breakpoint($medium-up) {
          font-size: 18px;
        }
      }
    }
    .checkout_loyalty_join_container {
      .error__message {
        li {
          list-style: none;
        }
      }
      .loyalty-join-panel {
        margin-left: 0;
        .birth__month,
        .birth__date {
          @include breakpoint($medium-up) {
            width: 47%;
          }
          height: 35px;
          line-height: 1.7;
          width: 48%;
          display: inline-block;
          text-transform: none;
          font-size: 16px;
          min-width: auto;
          &.error {
            border-color: $color-red;
          }
        }
        .birth__month {
          margin-right: 2%;
        }
        .label-content {
          @include breakpoint($medium-up) {
            font-size: 16px;
          }
          text-align: left;
          line-height: 1.2;
          font-size: 4.2vw;
          letter-spacing: 1px;
        }
        .button {
          &.join__now__btn {
            @include breakpoint($medium-up) {
              font-size: 15px;
            }
            font-size: 18px;
            width: 100%;
            &:hover {
              background-color: $color-drak-black;
            }
            &.btn--disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
  .confirm {
    #confirmation-panel {
      .confirmation {
        &-signedin {
          @include breakpoint($medium-up) {
            font-size: 15px;
          }
          font-size: 14px;
        }
      }
    }
  }
}
