///
/// @file global/_media-queries.scss
///
/// \brief Named media queries for easy use in nested selectors
///
/// In general these should be used in a mobile-first fashion, meaning
/// everything is coded for $small, then overridden for $medium-up and
/// overridden once more for $large-up. So you shouldn't even have to reference
/// the $small-*, $*-down or $*-only breakpoints, but they're here for now
/// just cause.
///

$xxsmall-down: max-width 480px;
$xxsmall-only: (321px, 480px);
$xxsmall-up: 321px;
$xxsmall-portrait: 376px;
$xsmall-down: max-width 640px;
$xsmall-only: (481px, 640px);
$xsmall-up: 481px;
$small-down: max-width 767px;
$small-only: (641px, 767px);
$small-up: 641px;
$mobile-landscape-up: 640px;
$medium-down: max-width 1024px;
$medium-only: (768px, 1024px);
$medium-up: 768px;
$med-small-up: 481px;
$landscape-up: 1024px;
$large-only: 1025px;
$large-up: 1025px;
$largest-up: 1281px;
$xxlarge-up: 1921px;
$portrait-down: max-width 768px;
$portrait-up: 769px;
$spp-medium-up: $portrait-up;
$mobile-up: min-width 676px;
$screen: 'only screen' !default;
$medium-range: (737px, 768px);
$large-range: (769px, 1024px); // iPad landscape: $large-up, $large-only

$ipad-below-landscape: max-width 1023px;

$medium-portrait-only: ($medium-up $landscape-up) (orientation portrait); // iPad portrait
$medium-landscape-only: ($medium-up $landscape-up) (orientation landscape); // iPad landscape
$ipad-pro-portrait-only: (width 834px 1194px) (orientation portrait); // iPad Pro portrait
$ipad-pro-landscape-only: (width 834px 1194px) (orientation landscape); // iPad Pro landscape

// OAB-media-queries
$medium-up-oab: '#{$screen} and (min-width:#{lower-bound($medium-range)})' !default;
$large-up-oab: '#{$screen} and (min-width:#{lower-bound($large-range)})' !default;

// Mobile devices
$iphone6-up: 375px;

//////// Unison.js stuff \\\\\\\\\
// create sass list to pass media query data
// if a breakpoint name changes, remember to
// update it in the list below as well
$mq-sync: xxsmall $xxsmall-up, xsmall $xsmall-up, small $small-up, medium $medium-up, landscape $landscape-up,
  large $large-up;

$medium-m: min-width 764px !default;
$medium-mid: max-width 812px !default;
