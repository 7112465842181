@if $loyalty-redesign == true {
  .overlay_loyalty_optout {
    #cboxClose {
      background: none;
      text-indent: 0;
      .icon--close {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  #loyalty__panel__offers {
    @include breakpoint($medium-up) {
      padding-bottom: 30px;
      border-bottom: 1px solid $color-light-gray;
    }
    .messages__container__loyalty {
      &:empty {
        margin: 0;
      }
      @include breakpoint($medium-up) {
        margin: 0;
      }
      margin: 10px 0 5px;
    }
    .loyalty__panel__offers {
      &__loader {
        @include breakpoint($medium-up) {
          text-align: left;
        }
        text-align: center;
      }
      &__title {
        @include breakpoint($medium-up) {
          font-size: 25px;
          margin: 30px 0 14px;
        }
        font-family: $font--futura-book;
        text-transform: uppercase;
        text-align: center;
        font-size: 18px;
        letter-spacing: 1px;
        margin: 20px 0 0;
      }
      &__subtitle {
        @include breakpoint($medium-up) {
          margin: 10px 0 7px;
        }
        font-family: $font--futura-book;
        text-align: center;
        font-size: 14px;
        letter-spacing: 1px;
      }
      &__promo {
        &__title {
          @include breakpoint($medium-up) {
            min-height: 22px;
          }
          min-height: 48px;
          font-family: $font--futura-demi;
          font-size: 15px;
          text-align: left;
          width: 100%;
          text-transform: uppercase;
          margin-bottom: 0;
          color: $color-drak-black;
        }
        &__description {
          @include breakpoint($medium-up) {
            min-height: 63px;
          }
          min-height: 147px;
          font-family: $font--futura-medium;
          font-size: 15px;
          letter-spacing: 0;
          margin-bottom: 0;
        }
      }
      &__cta {
        text-align: left;
      }
      &__list {
        &-container {
          @include breakpoint($medium-up) {
            margin-top: 20px;
          }
        }
        .slick-track {
          margin: 0 auto;
        }
        .slick-list {
          margin: 0 auto;
          padding: 0;
          width: 100%;
        }
        .slick-slider {
          @include breakpoint($medium-up) {
            padding: 0;
          }
          padding: 0 20px;
        }
        .slick-slide {
          text-align: center;
          img {
            display: inline-block;
          }
        }
        .slick-prev,
        .slick-next {
          @include breakpoint($medium-up) {
            top: 40%;
          }
          top: 25%;
          &:before {
            @include breakpoint($medium-up) {
              background-size: auto;
            }
            background-size: 30px;
            background-image: url(/media/images/global/arrow_down_black_old.png);
            content: '';
            display: block;
            background-repeat: no-repeat;
            width: 42px;
            height: 42px;
          }
        }
        .slick-prev {
          &:before {
            @include breakpoint($medium-up) {
              left: -14px;
            }
            left: 3px;
            transform: rotate(90deg);
            background-position-y: 23px;
          }
        }
        .slick-next {
          right: -28px;
          &:before {
            @include breakpoint($medium-up) {
              right: -8px;
              background-position-x: 5px;
            }
            transform: rotate(270deg);
            right: 7px;
            background-position-x: 11px;
          }
        }
      }
      &__offer {
        @include breakpoint($medium-up) {
          padding: 0 0.5em 3em 0.5em;
        }
        position: relative;
        border: none;
        padding: 0 15px;
        margin-bottom: 10px;
        &-data-container {
          text-align: left;
        }
      }
      &__remove {
        background-color: $color-white;
        color: $color-drak-black;
        border: 1px solid $color-drak-black;
      }
      &__button,
      &__remove,
      &__redeemed {
        @include breakpoint($medium-up) {
          height: 28px;
          display: inline-block;
          font-size: 13px;
          letter-spacing: 0.5px;
          line-height: 2.3;
        }
        width: auto;
        letter-spacing: 1px;
        line-height: 2;
        display: block;
        height: 32px;
        font-size: 16px;
        padding: 0 20px;
        &:hover,
        &:focus {
          text-decoration: none;
        }
        &.button--disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: $color-drak-black;
          box-shadow: none;
        }
      }
    }
  }
}
