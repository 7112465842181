.opc__review {
  .checkout__content {
    #review-panel {
      .sms-promotions {
        .email-and-sms-promotions {
          display: block;
          .checkout__panel--heading {
            display: inline;
            .expando-block__icon {
              top: 0px;
            }
          }
          .checkout__panel--content {
            margin-top: 1em;
          }
        }
      }
    }
  }
}
